@use 'sass:meta';
@tailwind base;
@tailwind components;
@tailwind utilities;

@include meta.load-css('base');

@include meta.load-css('tailstrap/btn');
@include meta.load-css('tailstrap/card');
@include meta.load-css('tailstrap/compat');
@include meta.load-css('tailstrap/forms');
@include meta.load-css('tailstrap/table');

@include meta.load-css('brand-fonts');
@include meta.load-css('font-awesome');
@include meta.load-css('status-helpers');
@include meta.load-css('account');
@include meta.load-css('global');
@include meta.load-css('invitations');
@include meta.load-css('tables');
@include meta.load-css('react-textarea-autocomplete');
@include meta.load-css('reactflow');
@include meta.load-css('plotly-graph');
@include meta.load-css('table-component');
@include meta.load-css('dj-forms');
