*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3192c680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e5e5;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Rubik, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #737373;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

* {
  scrollbar-color: initial;
  scrollbar-width: initial;
}

:root {
  --color-fg-actionable-hover: #ff6f1d;
  --color-fg-actionable: #d04b00;
  --color-fg-black: #000;
  --color-fg-body: #000;
  --color-fg-code: #d85b7a;
  --color-fg-danger: #cc3939;
  --color-fg-gray100: #f8f8f8;
  --color-fg-gray300: #f8f8f8;
  --color-fg-gray400: #e5e5e5;
  --color-fg-gray500: #cfcfcf;
  --color-fg-gray600: #737373;
  --color-fg-gray700: #3c3c3c;
  --color-fg-info: #3192c6;
  --color-fg-light: #f8f8f8;
  --color-fg-link: #d04b00;
  --color-fg-muted: #737373;
  --color-fg-orange: #ff6f1d;
  --color-fg-primary: #ffb083;
  --color-fg-red: #cc3939;
  --color-fg-secondary: #737373;
  --color-fg-success: #41a048;
  --color-fg-warning: #d97706;
  --color-fg-white: #fff;
  --color-fg-yellow: #d97706;
  --color-status-created: #cfcfcf;
  --color-status-queued: #737373;
  --color-status-started: #57acd9;
  --color-status-complete: #338639;
  --color-status-error: #cc3939;
  --color-status-stopping: #ff8107;
  --color-status-stopping-hard: #ff8107;
  --color-status-stopped: #ff6f1d;
  --color-vh-main-hit-pink: #ffb083;
  --color-vh-main-light-apricot: #ffd3ac;
  --color-vh-sec-carissma: #ef8499;
  --color-vh-sec-beauty-bush: #f0abb6;
  --color-vh-sec-eton-blue: #96cca3;
  --color-vh-sec-fuscous-gray: #3c3c3c;
  --color-vh-sec-very-light-gray: #cfcfcf;
  --color-vh-sec-desert-storm: #f8f8f8;
  --color-vh-sup-ship-cove: #7693b6;
  --color-vh-sup-seagull: #84c2e5;
  --color-vh-sup-olivine: #a2b676;
  --color-vh-sup-dull-lavender: #a384e5;
  --color-dark-100: #404040;
  --color-dark-200: #303030;
  --color-dark-300: #252525;
  --color-dark-400: #1a1a1a;
  --color-dark-500: #121212;
}

:where(.dark, .dark *), :where(.dark, .dark *) :before, :where(.dark, .dark *) :after {
  border-color: #404040;
}

body {
  font-size: 15px;
}

body:where(.dark, .dark *) {
  color: #fff;
  background-color: #121212;
}

h1 {
  color: #3c3c3c;
  margin-bottom: .5rem;
  font-family: Mont, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
  line-height: 1.2;
}

h1:where(.dark, .dark *) {
  color: #fff;
}

h1 {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

h2 {
  color: #3c3c3c;
  margin-bottom: .5rem;
  font-family: Mont, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
  line-height: 1.2;
}

h2:where(.dark, .dark *) {
  color: #fff;
}

h2 {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

h3 {
  color: #3c3c3c;
  margin-bottom: .5rem;
  font-family: Mont, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
  line-height: 1.2;
}

h3:where(.dark, .dark *) {
  color: #fff;
}

h3 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

h4 {
  color: #3c3c3c;
  margin-bottom: .5rem;
  font-family: Mont, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
  line-height: 1.2;
}

h4:where(.dark, .dark *) {
  color: #fff;
}

h4 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

h5, h6 {
  color: #3c3c3c;
  margin-bottom: .5rem;
  font-family: Mont, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
  line-height: 1.2;
}

h5:where(.dark, .dark *), h6:where(.dark, .dark *) {
  color: #fff;
}

h5, h6 {
  font-size: 1rem;
}

p {
  --xx-dummy: 1;
}

a {
  color: #3c3c3c;
  text-underline-offset: 4px;
  font-weight: 700;
  text-decoration-color: #737373;
  text-decoration-thickness: 1px;
}

a:hover {
  color: #000;
  text-decoration-line: underline;
}

a:where(.dark, .dark *) {
  color: #fff;
  text-decoration-color: #f8f8f8;
}

a:hover:where(.dark, .dark *) {
  color: #fff;
}

.\!container {
  width: 100% !important;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .\!container {
    max-width: 640px !important;
  }

  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .\!container {
    max-width: 768px !important;
  }

  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .\!container {
    max-width: 1024px !important;
  }

  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .\!container {
    max-width: 1280px !important;
  }

  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .\!container {
    max-width: 1536px !important;
  }

  .container {
    max-width: 1536px;
  }
}

.prose {
  color: var(--tw-prose-body);
  max-width: 65ch;
}

.prose :where(p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where([class~="lead"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  margin-top: 1.2em;
  margin-bottom: 1.2em;
  font-size: 1.25em;
  line-height: 1.6;
}

.prose :where(a):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-links);
  font-weight: 500;
  text-decoration: underline;
}

.prose :where(strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}

.prose :where(a strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote strong):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
  list-style-type: decimal;
}

.prose :where(ol[type="A"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="A" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}

.prose :where(ol[type="a" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}

.prose :where(ol[type="I"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="I" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: upper-roman;
}

.prose :where(ol[type="i" s]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: lower-roman;
}

.prose :where(ol[type="1"]):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  list-style-type: decimal;
}

.prose :where(ul):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
  padding-inline-start: 1.625em;
  list-style-type: disc;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-counters);
  font-weight: 400;
}

.prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}

.prose :where(dt):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.25em;
  font-weight: 600;
}

.prose :where(hr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-color: var(--tw-prose-hr);
  border-top-width: 1px;
  margin-top: 3em;
  margin-bottom: 3em;
}

.prose :where(blockquote):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-quotes);
  border-inline-start-width: .25rem;
  border-inline-start-color: var(--tw-prose-quote-borders);
  quotes: "“""”""‘""’";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-inline-start: 1em;
  font-style: italic;
  font-weight: 500;
}

.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):before {
  content: open-quote;
}

.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: close-quote;
}

.prose :where(h1):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 0;
  margin-bottom: .888889em;
  font-size: 2.25em;
  font-weight: 800;
  line-height: 1.11111;
}

.prose :where(h1 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 900;
}

.prose :where(h2):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1.33333;
}

.prose :where(h2 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 800;
}

.prose :where(h3):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.6em;
  margin-bottom: .6em;
  font-size: 1.25em;
  font-weight: 600;
  line-height: 1.6;
}

.prose :where(h3 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(h4):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  margin-top: 1.5em;
  margin-bottom: .5em;
  font-weight: 600;
  line-height: 1.5;
}

.prose :where(h4 strong):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-weight: 700;
}

.prose :where(img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(picture):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  display: block;
}

.prose :where(video):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(kbd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-kbd);
  box-shadow: 0 0 0 1px rgb(var(--tw-prose-kbd-shadows) / 10%), 0 3px 0 rgb(var(--tw-prose-kbd-shadows) / 10%);
  padding-top: .1875em;
  padding-inline-end: .375em;
  padding-bottom: .1875em;
  border-radius: .3125rem;
  padding-inline-start: .375em;
  font-family: inherit;
  font-size: .875em;
  font-weight: 500;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-code);
  font-size: .875em;
  font-weight: 600;
}

.prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: "`";
}

.prose :where(a code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h1 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(h2 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .875em;
}

.prose :where(h3 code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
  font-size: .9em;
}

.prose :where(h4 code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(blockquote code):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(thead th code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: inherit;
}

.prose :where(pre):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  padding-top: .857143em;
  padding-inline-end: 1.14286em;
  padding-bottom: .857143em;
  border-radius: .375rem;
  margin-top: 1.71429em;
  margin-bottom: 1.71429em;
  padding-inline-start: 1.14286em;
  font-size: .875em;
  font-weight: 400;
  line-height: 1.71429;
  overflow-x: auto;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
  background-color: #0000;
  border-width: 0;
  border-radius: 0;
  padding: 0;
}

.prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose :where(pre code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: none;
}

.prose :where(table):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  table-layout: auto;
  width: 100%;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: .875em;
  line-height: 1.71429;
}

.prose :where(thead):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-th-borders);
}

.prose :where(thead th):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  vertical-align: bottom;
  padding-inline-end: .571429em;
  padding-bottom: .571429em;
  padding-inline-start: .571429em;
  font-weight: 600;
}

.prose :where(tbody tr):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}

.prose :where(tbody tr:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-bottom-width: 0;
}

.prose :where(tbody td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: baseline;
}

.prose :where(tfoot):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  border-top-width: 1px;
  border-top-color: var(--tw-prose-th-borders);
}

.prose :where(tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  vertical-align: top;
}

.prose :where(th, td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  text-align: start;
}

.prose :where(figure > *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(figcaption):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  margin-top: .857143em;
  font-size: .875em;
  line-height: 1.42857;
}

.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-kbd: #111827;
  --tw-prose-kbd-shadows: 17 24 39;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-kbd: #fff;
  --tw-prose-invert-kbd-shadows: 255 255 255;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: #00000080;
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}

.prose :where(picture > img):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}

.prose :where(li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  margin-bottom: .5em;
}

.prose :where(ol > li):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(ul > li):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: .375em;
}

.prose :where(.prose > ul > li p):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(.prose > ul > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ul > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(.prose > ol > li > p:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
}

.prose :where(.prose > ol > li > p:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}

.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .75em;
  margin-bottom: .75em;
}

.prose :where(dl):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}

.prose :where(dd):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: .5em;
  padding-inline-start: 1.625em;
}

.prose :where(hr + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h2 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h3 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)), .prose :where(h4 + *):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(thead th:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose :where(thead th:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose :where(tbody td, tfoot td):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-top: .571429em;
  padding-inline-end: .571429em;
  padding-bottom: .571429em;
  padding-inline-start: .571429em;
}

.prose :where(tbody td:first-child, tfoot td:first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-start: 0;
}

.prose :where(tbody td:last-child, tfoot td:last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  padding-inline-end: 0;
}

.prose :where(figure):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}

.prose :where(.prose > :first-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-top: 0;
}

.prose :where(.prose > :last-child):not(:where([class~="not-prose"], [class~="not-prose"] *)) {
  margin-bottom: 0;
}

.btn {
  text-align: center;
  border-radius: .25rem;
  padding: .5rem 1rem;
}

.btn[disabled], .btn.disabled, .btn.\!disabled {
  cursor: not-allowed;
  opacity: .25;
}

.btn:hover {
  color: currentColor;
  --tw-brightness: brightness(1.05);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.btn-success {
  color: #fff;
  background-color: #41a048;
}

.btn-success:hover {
  color: #fff;
  --tw-brightness: brightness(.95);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.btn-success i {
  color: currentColor;
}

.btn-primary {
  color: #3c3c3c;
  background-color: #ffb083;
}

.btn-primary:hover {
  color: #3c3c3c;
}

.btn-link {
  color: #3c3c3c;
  background-image: none;
}

.btn-block {
  width: 100%;
  display: block;
}

.btn-group {
  display: inline-flex;
}

.btn-group > :not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > :not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.card {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  background-color: #fff;
  border-width: 1px;
  border-color: #cfcfcf;
  border-radius: .5rem;
  flex-direction: column;
  display: flex;
  position: relative;
}

.card:where(.dark, .dark *) {
  background-color: #1a1a1a;
  border-color: #303030;
}

.tpanel-closed .card-header {
  border-width: 0;
  border-radius: .5rem;
}

.card-body {
  flex: auto;
  padding: .75rem;
}

.vh-table th {
  text-align: inherit;
  text-align: match-parent;
  text-align: -webkit-match-parent;
}

.vh-table > thead > tr > *, .vh-table > tbody > tr > *, .vh-table > tfoot > tr > * {
  border-bottom-width: 1px;
}

.vh-table.vh-table-size-regular > thead > tr > *, .vh-table.vh-table-size-regular > tbody > tr > *, .vh-table.vh-table-size-regular > tfoot > tr > * {
  padding: .5rem;
}

.vh-table.vh-table-size-small > thead > tr > *, .vh-table.vh-table-size-small > tbody > tr > *, .vh-table.vh-table-size-small > tfoot > tr > * {
  padding: .25rem;
  font-size: .875rem;
  line-height: 1.25rem;
}

.vh-table > tbody {
  vertical-align: inherit;
}

.vh-table > thead {
  vertical-align: bottom;
}

.vh-table.vh-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: #f8f8f8;
}

.vh-table.vh-striped > tbody > tr:nth-of-type(odd) > :where(.dark, .dark *) {
  background-color: #1a1a1a;
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.\!pointer-events-none {
  pointer-events: none !important;
}

.pointer-events-none {
  pointer-events: none;
}

.\!visible {
  visibility: visible !important;
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

.collapse {
  visibility: collapse;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.\!absolute {
  position: absolute !important;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.inset-0 {
  inset: 0;
}

.\!-left-\[22px\] {
  left: -22px !important;
}

.\!-left-\[5px\] {
  left: -5px !important;
}

.\!-right-\[22px\] {
  right: -22px !important;
}

.\!-right-\[5px\] {
  right: -5px !important;
}

.-right-1 {
  right: -.25rem;
}

.-start-4 {
  inset-inline-start: -1rem;
}

.-top-8 {
  top: -2rem;
}

.-top-\[20px\] {
  top: -20px;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.left-1\/2 {
  left: 50%;
}

.left-5 {
  left: 1.25rem;
}

.left-\[50vw\] {
  left: 50vw;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.top-1\/2 {
  top: 50%;
}

.top-5 {
  top: 1.25rem;
}

.top-\[1em\] {
  top: 1em;
}

.isolate {
  isolation: isolate;
}

.z-0 {
  z-index: 0;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.z-\[1044\] {
  z-index: 1044;
}

.z-\[1055\] {
  z-index: 1055;
}

.z-\[1056\] {
  z-index: 1056;
}

.z-\[1070\] {
  z-index: 1070;
}

.z-\[1\] {
  z-index: 1;
}

.z-\[9900\] {
  z-index: 9900;
}

.col-auto {
  grid-column: auto;
}

.col-span-1 {
  grid-column: span 1 / span 1;
}

.col-span-10 {
  grid-column: span 10 / span 10;
}

.col-span-11 {
  grid-column: span 11 / span 11;
}

.col-span-12 {
  grid-column: span 12 / span 12;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.col-span-3 {
  grid-column: span 3 / span 3;
}

.col-span-4 {
  grid-column: span 4 / span 4;
}

.col-span-5 {
  grid-column: span 5 / span 5;
}

.col-span-6 {
  grid-column: span 6 / span 6;
}

.col-span-7 {
  grid-column: span 7 / span 7;
}

.col-span-8 {
  grid-column: span 8 / span 8;
}

.col-span-9 {
  grid-column: span 9 / span 9;
}

.col-span-full {
  grid-column: 1 / -1;
}

.-col-start-1 {
  grid-column-start: -1;
}

.-col-start-10 {
  grid-column-start: -10;
}

.-col-start-11 {
  grid-column-start: -11;
}

.-col-start-12 {
  grid-column-start: -12;
}

.-col-start-13 {
  grid-column-start: -13;
}

.-col-start-2 {
  grid-column-start: -2;
}

.-col-start-3 {
  grid-column-start: -3;
}

.-col-start-4 {
  grid-column-start: -4;
}

.-col-start-5 {
  grid-column-start: -5;
}

.-col-start-6 {
  grid-column-start: -6;
}

.-col-start-7 {
  grid-column-start: -7;
}

.-col-start-8 {
  grid-column-start: -8;
}

.-col-start-9 {
  grid-column-start: -9;
}

.col-start-1 {
  grid-column-start: 1;
}

.col-start-10 {
  grid-column-start: 10;
}

.col-start-11 {
  grid-column-start: 11;
}

.col-start-12 {
  grid-column-start: 12;
}

.col-start-13 {
  grid-column-start: 13;
}

.col-start-2 {
  grid-column-start: 2;
}

.col-start-3 {
  grid-column-start: 3;
}

.col-start-4 {
  grid-column-start: 4;
}

.col-start-5 {
  grid-column-start: 5;
}

.col-start-6 {
  grid-column-start: 6;
}

.col-start-7 {
  grid-column-start: 7;
}

.col-start-8 {
  grid-column-start: 8;
}

.col-start-9 {
  grid-column-start: 9;
}

.col-start-auto {
  grid-column-start: auto;
}

.-col-end-1 {
  grid-column-end: -1;
}

.-col-end-10 {
  grid-column-end: -10;
}

.-col-end-11 {
  grid-column-end: -11;
}

.-col-end-12 {
  grid-column-end: -12;
}

.-col-end-13 {
  grid-column-end: -13;
}

.-col-end-2 {
  grid-column-end: -2;
}

.-col-end-3 {
  grid-column-end: -3;
}

.-col-end-4 {
  grid-column-end: -4;
}

.-col-end-5 {
  grid-column-end: -5;
}

.-col-end-6 {
  grid-column-end: -6;
}

.-col-end-7 {
  grid-column-end: -7;
}

.-col-end-8 {
  grid-column-end: -8;
}

.-col-end-9 {
  grid-column-end: -9;
}

.col-end-1 {
  grid-column-end: 1;
}

.col-end-10 {
  grid-column-end: 10;
}

.col-end-11 {
  grid-column-end: 11;
}

.col-end-12 {
  grid-column-end: 12;
}

.col-end-13 {
  grid-column-end: 13;
}

.col-end-2 {
  grid-column-end: 2;
}

.col-end-3 {
  grid-column-end: 3;
}

.col-end-4 {
  grid-column-end: 4;
}

.col-end-5 {
  grid-column-end: 5;
}

.col-end-6 {
  grid-column-end: 6;
}

.col-end-7 {
  grid-column-end: 7;
}

.col-end-8 {
  grid-column-end: 8;
}

.col-end-9 {
  grid-column-end: 9;
}

.col-end-auto {
  grid-column-end: auto;
}

.row-auto {
  grid-row: auto;
}

.row-span-1 {
  grid-row: span 1 / span 1;
}

.row-span-10 {
  grid-row: span 10 / span 10;
}

.row-span-11 {
  grid-row: span 11 / span 11;
}

.row-span-12 {
  grid-row: span 12 / span 12;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.row-span-3 {
  grid-row: span 3 / span 3;
}

.row-span-4 {
  grid-row: span 4 / span 4;
}

.row-span-5 {
  grid-row: span 5 / span 5;
}

.row-span-6 {
  grid-row: span 6 / span 6;
}

.row-span-7 {
  grid-row: span 7 / span 7;
}

.row-span-8 {
  grid-row: span 8 / span 8;
}

.row-span-9 {
  grid-row: span 9 / span 9;
}

.row-span-full {
  grid-row: 1 / -1;
}

.-row-start-1 {
  grid-row-start: -1;
}

.-row-start-10 {
  grid-row-start: -10;
}

.-row-start-11 {
  grid-row-start: -11;
}

.-row-start-12 {
  grid-row-start: -12;
}

.-row-start-13 {
  grid-row-start: -13;
}

.-row-start-2 {
  grid-row-start: -2;
}

.-row-start-3 {
  grid-row-start: -3;
}

.-row-start-4 {
  grid-row-start: -4;
}

.-row-start-5 {
  grid-row-start: -5;
}

.-row-start-6 {
  grid-row-start: -6;
}

.-row-start-7 {
  grid-row-start: -7;
}

.-row-start-8 {
  grid-row-start: -8;
}

.-row-start-9 {
  grid-row-start: -9;
}

.row-start-1 {
  grid-row-start: 1;
}

.row-start-10 {
  grid-row-start: 10;
}

.row-start-11 {
  grid-row-start: 11;
}

.row-start-12 {
  grid-row-start: 12;
}

.row-start-13 {
  grid-row-start: 13;
}

.row-start-2 {
  grid-row-start: 2;
}

.row-start-3 {
  grid-row-start: 3;
}

.row-start-4 {
  grid-row-start: 4;
}

.row-start-5 {
  grid-row-start: 5;
}

.row-start-6 {
  grid-row-start: 6;
}

.row-start-7 {
  grid-row-start: 7;
}

.row-start-8 {
  grid-row-start: 8;
}

.row-start-9 {
  grid-row-start: 9;
}

.row-start-auto {
  grid-row-start: auto;
}

.-row-end-1 {
  grid-row-end: -1;
}

.-row-end-10 {
  grid-row-end: -10;
}

.-row-end-11 {
  grid-row-end: -11;
}

.-row-end-12 {
  grid-row-end: -12;
}

.-row-end-13 {
  grid-row-end: -13;
}

.-row-end-2 {
  grid-row-end: -2;
}

.-row-end-3 {
  grid-row-end: -3;
}

.-row-end-4 {
  grid-row-end: -4;
}

.-row-end-5 {
  grid-row-end: -5;
}

.-row-end-6 {
  grid-row-end: -6;
}

.-row-end-7 {
  grid-row-end: -7;
}

.-row-end-8 {
  grid-row-end: -8;
}

.-row-end-9 {
  grid-row-end: -9;
}

.row-end-1 {
  grid-row-end: 1;
}

.row-end-10 {
  grid-row-end: 10;
}

.row-end-11 {
  grid-row-end: 11;
}

.row-end-12 {
  grid-row-end: 12;
}

.row-end-13 {
  grid-row-end: 13;
}

.row-end-2 {
  grid-row-end: 2;
}

.row-end-3 {
  grid-row-end: 3;
}

.row-end-4 {
  grid-row-end: 4;
}

.row-end-5 {
  grid-row-end: 5;
}

.row-end-6 {
  grid-row-end: 6;
}

.row-end-7 {
  grid-row-end: 7;
}

.row-end-8 {
  grid-row-end: 8;
}

.row-end-9 {
  grid-row-end: 9;
}

.row-end-auto {
  grid-row-end: auto;
}

.float-start {
  float: inline-start;
}

.float-end {
  float: inline-end;
}

.float-right {
  float: right;
}

.-m-1 {
  margin: -.25rem;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: .25rem;
}

.m-2 {
  margin: .5rem;
}

.m-3 {
  margin: .75rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-auto {
  margin: auto;
}

.mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.\!mb-0 {
  margin-bottom: 0 !important;
}

.\!mb-1 {
  margin-bottom: .25rem !important;
}

.-mb-1 {
  margin-bottom: -.25rem;
}

.-mb-px {
  margin-bottom: -1px;
}

.-ml-1 {
  margin-left: -.25rem;
}

.-ml-\[20em\] {
  margin-left: -20em;
}

.-mr-1 {
  margin-right: -.25rem;
}

.-mr-2 {
  margin-right: -.5rem;
}

.-mr-px {
  margin-right: -1px;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-2 {
  margin-top: -.5rem;
}

.-mt-3 {
  margin-top: -.75rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.-mt-\[2px\] {
  margin-top: -2px;
}

.-mt-\[5px\] {
  margin-top: -5px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-0\.5 {
  margin-bottom: .125rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-28 {
  margin-bottom: 7rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.me-0\.5 {
  margin-inline-end: .125rem;
}

.me-1 {
  margin-inline-end: .25rem;
}

.me-2 {
  margin-inline-end: .5rem;
}

.me-3 {
  margin-inline-end: .75rem;
}

.me-4 {
  margin-inline-end: 1rem;
}

.ml-0\.5 {
  margin-left: .125rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-1\.5 {
  margin-left: .375rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-1\.5 {
  margin-right: .375rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mr-auto {
  margin-right: auto;
}

.ms-0 {
  margin-inline-start: 0;
}

.ms-1 {
  margin-inline-start: .25rem;
}

.ms-2 {
  margin-inline-start: .5rem;
}

.ms-3 {
  margin-inline-start: .75rem;
}

.ms-4 {
  margin-inline-start: 1rem;
}

.ms-auto {
  margin-inline-start: auto;
}

.mt-0 {
  margin-top: 0;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mt-px {
  margin-top: 1px;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.\!inline {
  display: inline !important;
}

.inline {
  display: inline;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.table {
  display: table;
}

.\!table-cell {
  display: table-cell !important;
}

.table-row-group {
  display: table-row-group;
}

.table-row {
  display: table-row;
}

.grid {
  display: grid;
}

.contents {
  display: contents;
}

.\!hidden {
  display: none !important;
}

.hidden {
  display: none;
}

.\!size-2 {
  width: .5rem !important;
  height: .5rem !important;
}

.size-10 {
  width: 2.5rem;
  height: 2.5rem;
}

.size-12 {
  width: 3rem;
  height: 3rem;
}

.size-2 {
  width: .5rem;
  height: .5rem;
}

.size-3 {
  width: .75rem;
  height: .75rem;
}

.size-4 {
  width: 1rem;
  height: 1rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.size-6 {
  width: 1.5rem;
  height: 1.5rem;
}

.size-8 {
  width: 2rem;
  height: 2rem;
}

.size-\[18\.375px\] {
  width: 18.375px;
  height: 18.375px;
}

.h-1 {
  height: .25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-14 {
  height: 3.5rem;
}

.h-3 {
  height: .75rem;
}

.h-32 {
  height: 8rem;
}

.h-40 {
  height: 10rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-80 {
  height: 20rem;
}

.h-9 {
  height: 2.25rem;
}

.h-96 {
  height: 24rem;
}

.h-\[0\.6em\] {
  height: .6em;
}

.h-\[20px\] {
  height: 20px;
}

.h-\[25em\] {
  height: 25em;
}

.h-\[42px\] {
  height: 42px;
}

.h-\[45dvh\] {
  height: 45dvh;
}

.h-\[600px\] {
  height: 600px;
}

.h-\[90dvh\] {
  height: 90dvh;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-px {
  height: 1px;
}

.h-screen {
  height: 100vh;
}

.max-h-0 {
  max-height: 0;
}

.max-h-14 {
  max-height: 3.5rem;
}

.max-h-3\.5 {
  max-height: .875rem;
}

.max-h-48 {
  max-height: 12rem;
}

.max-h-64 {
  max-height: 16rem;
}

.max-h-80 {
  max-height: 20rem;
}

.max-h-96 {
  max-height: 24rem;
}

.max-h-\[40vh\] {
  max-height: 40vh;
}

.max-h-\[60dvh\] {
  max-height: 60dvh;
}

.max-h-\[60vh\] {
  max-height: 60vh;
}

.max-h-\[620px\] {
  max-height: 620px;
}

.max-h-\[75dvh\] {
  max-height: 75dvh;
}

.max-h-\[90vh\] {
  max-height: 90vh;
}

.max-h-full {
  max-height: 100%;
}

.max-h-screen {
  max-height: 100vh;
}

.\!min-h-0 {
  min-height: 0 !important;
}

.min-h-0 {
  min-height: 0;
}

.min-h-10 {
  min-height: 2.5rem;
}

.min-h-24 {
  min-height: 6rem;
}

.min-h-28 {
  min-height: 7rem;
}

.min-h-32 {
  min-height: 8rem;
}

.min-h-40 {
  min-height: 10rem;
}

.min-h-48 {
  min-height: 12rem;
}

.min-h-60 {
  min-height: 15rem;
}

.min-h-72 {
  min-height: 18rem;
}

.min-h-8 {
  min-height: 2rem;
}

.min-h-\[200px\] {
  min-height: 200px;
}

.min-h-\[25em\] {
  min-height: 25em;
}

.min-h-\[480px\] {
  min-height: 480px;
}

.min-h-\[500px\] {
  min-height: 500px;
}

.min-h-\[50rem\] {
  min-height: 50rem;
}

.min-h-\[50vh\] {
  min-height: 50vh;
}

.min-h-\[600px\] {
  min-height: 600px;
}

.min-h-\[60vh\] {
  min-height: 60vh;
}

.min-h-screen {
  min-height: 100vh;
}

.w-0 {
  width: 0;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-10 {
  width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-2\/5 {
  width: 40%;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/5 {
  width: 60%;
}

.w-4 {
  width: 1rem;
}

.w-4\/5 {
  width: 80%;
}

.w-40 {
  width: 10rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-7\/12 {
  width: 58.3333%;
}

.w-8 {
  width: 2rem;
}

.w-80 {
  width: 20rem;
}

.w-9 {
  width: 2.25rem;
}

.w-96 {
  width: 24rem;
}

.w-\[20\%\] {
  width: 20%;
}

.w-\[23\%\] {
  width: 23%;
}

.w-\[250px\] {
  width: 250px;
}

.w-\[30\%\] {
  width: 30%;
}

.w-\[3em\] {
  width: 3em;
}

.w-\[40\%\] {
  width: 40%;
}

.w-\[40em\] {
  width: 40em;
}

.w-\[55\%\] {
  width: 55%;
}

.w-\[7\%\] {
  width: 7%;
}

.w-\[70\%\] {
  width: 70%;
}

.w-auto {
  width: auto;
}

.w-fit {
  width: fit-content;
}

.w-full {
  width: 100%;
}

.w-px {
  width: 1px;
}

.min-w-12 {
  min-width: 3rem;
}

.min-w-24 {
  min-width: 6rem;
}

.min-w-32 {
  min-width: 8rem;
}

.min-w-36 {
  min-width: 9rem;
}

.min-w-40 {
  min-width: 10rem;
}

.min-w-48 {
  min-width: 12rem;
}

.min-w-5 {
  min-width: 1.25rem;
}

.min-w-56 {
  min-width: 14rem;
}

.min-w-60 {
  min-width: 15rem;
}

.min-w-64 {
  min-width: 16rem;
}

.min-w-8 {
  min-width: 2rem;
}

.min-w-80 {
  min-width: 20rem;
}

.min-w-96 {
  min-width: 24rem;
}

.min-w-\[100px\] {
  min-width: 100px;
}

.min-w-\[200px\] {
  min-width: 200px;
}

.min-w-\[35em\] {
  min-width: 35em;
}

.min-w-\[50px\] {
  min-width: 50px;
}

.min-w-fit {
  min-width: fit-content;
}

.min-w-full {
  min-width: 100%;
}

.min-w-min {
  min-width: min-content;
}

.max-w-16 {
  max-width: 4rem;
}

.max-w-2xl {
  max-width: 42rem;
}

.max-w-32 {
  max-width: 8rem;
}

.max-w-3xl {
  max-width: 48rem;
}

.max-w-44 {
  max-width: 11rem;
}

.max-w-48 {
  max-width: 12rem;
}

.max-w-52 {
  max-width: 13rem;
}

.max-w-60 {
  max-width: 15rem;
}

.max-w-64 {
  max-width: 16rem;
}

.max-w-72 {
  max-width: 18rem;
}

.max-w-7xl {
  max-width: 80rem;
}

.max-w-80 {
  max-width: 20rem;
}

.max-w-96 {
  max-width: 24rem;
}

.max-w-\[400px\] {
  max-width: 400px;
}

.max-w-\[75px\] {
  max-width: 75px;
}

.max-w-fit {
  max-width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-min {
  max-width: min-content;
}

.max-w-prose {
  max-width: 65ch;
}

.max-w-screen-lg {
  max-width: 1024px;
}

.max-w-screen-md {
  max-width: 768px;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.max-w-xl {
  max-width: 36rem;
}

.flex-1 {
  flex: 1;
}

.flex-\[1_1_200px\] {
  flex: 200px;
}

.flex-\[3_3_0\%\] {
  flex: 3 3;
}

.flex-auto {
  flex: auto;
}

.flex-initial {
  flex: 0 auto;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-grow {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.grow {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}

.grow-\[3\] {
  flex-grow: 3;
}

.basis-1\/3 {
  flex-basis: 33.3333%;
}

.basis-1\/5 {
  flex-basis: 20%;
}

.basis-2\/3 {
  flex-basis: 66.6667%;
}

.basis-4\/5 {
  flex-basis: 80%;
}

.border-collapse {
  border-collapse: collapse;
}

.-translate-x-1\/2 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-1\/2 {
  --tw-translate-y: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-x-\[-50\%\] {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-0 {
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-\[-100\%\] {
  --tw-translate-y: -100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-0 {
  --tw-rotate: 0deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[-180deg\] {
  --tw-rotate: -180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-\[180deg\] {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-ping {
  animation: 1s cubic-bezier(0, 0, .2, 1) infinite ping;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: 1s linear infinite spin;
}

.\!cursor-grab {
  cursor: grab !important;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.cursor-help {
  cursor: help;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.touch-none {
  touch-action: none;
}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

.resize-none {
  resize: none;
}

.resize {
  resize: both;
}

.list-disc {
  list-style-type: disc;
}

.list-none {
  list-style-type: none;
}

.appearance-none {
  appearance: none;
}

.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}

.grid-flow-row {
  grid-auto-flow: row;
}

.grid-flow-col {
  grid-auto-flow: column;
}

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}

.grid-flow-col-dense {
  grid-auto-flow: column dense;
}

.auto-rows-auto {
  grid-auto-rows: auto;
}

.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}

.auto-rows-max {
  grid-auto-rows: max-content;
}

.auto-rows-min {
  grid-auto-rows: min-content;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-\[0_1fr_0\] {
  grid-template-columns: 0 1fr 0;
}

.grid-cols-\[10rem_auto\] {
  grid-template-columns: 10rem auto;
}

.grid-cols-\[1fr_2fr\] {
  grid-template-columns: 1fr 2fr;
}

.grid-cols-\[3fr_2fr\] {
  grid-template-columns: 3fr 2fr;
}

.grid-cols-\[auto\,1fr\] {
  grid-template-columns: auto 1fr;
}

.grid-cols-\[auto\,auto\,1fr\] {
  grid-template-columns: auto auto 1fr;
}

.grid-cols-\[auto_1fr\] {
  grid-template-columns: auto 1fr;
}

.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}

.grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr));
}

.grid-rows-11 {
  grid-template-rows: repeat(11, minmax(0, 1fr));
}

.grid-rows-12 {
  grid-template-rows: repeat(12, minmax(0, 1fr));
}

.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}

.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr));
}

.grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr));
}

.grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr));
}

.grid-rows-none {
  grid-template-rows: none;
}

.grid-rows-subgrid {
  grid-template-rows: subgrid;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.place-content-end {
  place-content: end;
}

.content-center {
  align-content: center;
}

.content-between {
  align-content: space-between;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-items-center {
  justify-items: center;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-1\.5 {
  gap: .375rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-8 {
  gap: 2rem;
}

.gap-x-2 {
  column-gap: .5rem;
}

.gap-x-3 {
  column-gap: .75rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-1 {
  row-gap: .25rem;
}

.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.25rem * var(--tw-space-x-reverse));
  margin-left: calc(.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(.5rem * var(--tw-space-x-reverse));
  margin-left: calc(.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.25rem * var(--tw-space-y-reverse));
}

.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(.5rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]), .divide-vh-sec-very-light-gray > :not([hidden]) ~ :not([hidden]) {
  border-color: #cfcfcf;
}

.place-self-start {
  place-self: start;
}

.self-start {
  align-self: flex-start;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.justify-self-end {
  justify-self: end;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-scroll {
  overflow: scroll;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-hidden {
  overflow-y: hidden;
}

.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.overflow-ellipsis, .text-ellipsis {
  text-overflow: ellipsis;
}

.whitespace-normal {
  white-space: normal;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.whitespace-pre {
  white-space: pre;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.text-wrap {
  text-wrap: wrap;
}

.text-nowrap {
  text-wrap: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.break-all {
  word-break: break-all;
}

.rounded {
  border-radius: .25rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-none {
  border-radius: 0;
}

.rounded-sm {
  border-radius: .125rem;
}

.\!rounded-r-none {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-b {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-b-lg {
  border-bottom-right-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.rounded-b-md {
  border-bottom-right-radius: .375rem;
  border-bottom-left-radius: .375rem;
}

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-r {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rounded-t {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.rounded-t-lg {
  border-top-left-radius: .5rem;
  border-top-right-radius: .5rem;
}

.rounded-t-md {
  border-top-left-radius: .375rem;
  border-top-right-radius: .375rem;
}

.rounded-bl {
  border-bottom-left-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.border-2 {
  border-width: 2px;
}

.border-4 {
  border-width: 4px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-y-8 {
  border-top-width: 8px;
  border-bottom-width: 8px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-b-4 {
  border-bottom-width: 4px;
}

.border-e {
  border-inline-end-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-l-4 {
  border-left-width: 4px;
}

.border-r-0 {
  border-right-width: 0;
}

.border-r-4 {
  border-right-width: 4px;
}

.border-r-8 {
  border-right-width: 8px;
}

.border-s {
  border-inline-start-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-0 {
  border-top-width: 0;
}

.\!border-solid {
  border-style: solid !important;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-none {
  border-style: none;
}

.\!border-gray-300 {
  border-color: #cfcfcf !important;
}

.\!border-peach {
  border-color: #ffb083 !important;
}

.border-blue-400 {
  border-color: #57acd9;
}

.border-blue-500 {
  border-color: #3192c6;
}

.border-blue-500\/60 {
  border-color: #3192c699;
}

.border-current {
  border-color: currentColor;
}

.border-dark-200 {
  border-color: #303030;
}

.border-gray {
  border-color: #cfcfcf;
}

.border-gray-100 {
  border-color: #f8f8f8;
}

.border-gray-200 {
  border-color: #e5e5e5;
}

.border-gray-300 {
  border-color: #cfcfcf;
}

.border-gray-300\/60 {
  border-color: #cfcfcf99;
}

.border-gray-400 {
  border-color: #737373;
}

.border-gray-500 {
  border-color: #3c3c3c;
}

.border-gray-500\/60 {
  border-color: #3c3c3c99;
}

.border-green, .border-green-400 {
  border-color: #41a048;
}

.border-green-500 {
  border-color: #338639;
}

.border-green-500\/60 {
  border-color: #33863999;
}

.border-orange-400 {
  border-color: #ff6f1d;
}

.border-peach {
  border-color: #ffb083;
}

.border-peach-500 {
  border-color: #fd9d65;
}

.border-peach-500\/60 {
  border-color: #fd9d6599;
}

.border-pink-300 {
  border-color: #efb2bd;
}

.border-pink-500\/60 {
  border-color: #d85b7a99;
}

.border-purple-500\/60 {
  border-color: #a384e599;
}

.border-red, .border-red-500 {
  border-color: #cc3939;
}

.border-red-500\/60 {
  border-color: #cc393999;
}

.border-slate-500\/60 {
  border-color: #7693b699;
}

.border-status-complete {
  border-color: #338639;
}

.border-status-error {
  border-color: #cc3939;
}

.border-status-queued {
  border-color: #737373;
}

.border-status-started {
  border-color: #57acd9;
}

.border-status-stopped {
  border-color: #ff6f1d;
}

.border-transparent {
  border-color: #0000;
}

.border-white {
  border-color: #fff;
}

.border-yellow-500 {
  border-color: #d97706;
}

.border-yellow-500\/60 {
  border-color: #d9770699;
}

.border-y-blue-400 {
  border-top-color: #57acd9;
  border-bottom-color: #57acd9;
}

.border-y-gray {
  border-top-color: #cfcfcf;
  border-bottom-color: #cfcfcf;
}

.border-y-green {
  border-top-color: #41a048;
  border-bottom-color: #41a048;
}

.border-y-orange-400 {
  border-top-color: #ff6f1d;
  border-bottom-color: #ff6f1d;
}

.border-y-red {
  border-top-color: #cc3939;
  border-bottom-color: #cc3939;
}

.\!border-r-transparent {
  border-right-color: #0000 !important;
}

.border-b-\[rgba\(0\,0\,0\,0\.5\)\] {
  border-bottom-color: #00000080;
}

.border-b-black {
  border-bottom-color: #000;
}

.border-b-blue-400 {
  border-bottom-color: #57acd9;
}

.border-b-current {
  border-bottom-color: currentColor;
}

.border-b-gray {
  border-bottom-color: #cfcfcf;
}

.border-b-gray-100 {
  border-bottom-color: #f8f8f8;
}

.border-b-gray-200 {
  border-bottom-color: #e5e5e5;
}

.border-b-gray-300 {
  border-bottom-color: #cfcfcf;
}

.border-b-gray-400 {
  border-bottom-color: #737373;
}

.border-b-green {
  border-bottom-color: #41a048;
}

.border-b-orange-400 {
  border-bottom-color: #ff6f1d;
}

.border-b-peach-400 {
  border-bottom-color: #ffb083;
}

.border-b-peach-500 {
  border-bottom-color: #fd9d65;
}

.border-b-red {
  border-bottom-color: #cc3939;
}

.border-l-dark-200 {
  border-left-color: #303030;
}

.border-l-slate-400 {
  border-left-color: #8dacc7;
}

.border-r-dark-200 {
  border-right-color: #303030;
}

.border-r-slate-400 {
  border-right-color: #8dacc7;
}

.border-t-peach-300 {
  border-top-color: #ffd3ac;
}

.\!bg-gray-400 {
  background-color: #737373 !important;
}

.\!bg-peach, .\!bg-peach-400 {
  background-color: #ffb083 !important;
}

.\!bg-red {
  background-color: #cc3939 !important;
}

.\!bg-white {
  background-color: #fff !important;
}

.bg-\[\#050b28\] {
  background-color: #050b28;
}

.bg-\[\#ffea00\] {
  background-color: #ffea00;
}

.bg-\[var\(--tag-bg\)\] {
  background-color: var(--tag-bg);
}

.bg-black {
  background-color: #000;
}

.bg-black\/25 {
  background-color: #00000040;
}

.bg-black\/80 {
  background-color: #000c;
}

.bg-black\/85 {
  background-color: #000000d9;
}

.bg-blue {
  background-color: #57acd9;
}

.bg-blue-100 {
  background-color: #e5f0f9;
}

.bg-blue-100\/40 {
  background-color: #e5f0f966;
}

.bg-blue-400 {
  background-color: #57acd9;
}

.bg-blue-500 {
  background-color: #3192c6;
}

.bg-dark-400 {
  background-color: #1a1a1a;
}

.bg-dark-500 {
  background-color: #121212;
}

.bg-fg-actionable {
  background-color: #d04b00;
}

.bg-gray-100 {
  background-color: #f8f8f8;
}

.bg-gray-100\/40 {
  background-color: #f8f8f866;
}

.bg-gray-100\/60 {
  background-color: #f8f8f899;
}

.bg-gray-100\/80 {
  background-color: #f8f8f8cc;
}

.bg-gray-200 {
  background-color: #e5e5e5;
}

.bg-gray-200\/80 {
  background-color: #e5e5e5cc;
}

.bg-gray-300 {
  background-color: #cfcfcf;
}

.bg-gray-400 {
  background-color: #737373;
}

.bg-gray-500 {
  background-color: #3c3c3c;
}

.bg-green {
  background-color: #41a048;
}

.bg-green-100 {
  background-color: #dcefde;
}

.bg-green-100\/10 {
  background-color: #dcefde1a;
}

.bg-green-100\/40 {
  background-color: #dcefde66;
}

.bg-green-100\/60 {
  background-color: #dcefde99;
}

.bg-green-400 {
  background-color: #41a048;
}

.bg-green-500 {
  background-color: #338639;
}

.bg-orange-400 {
  background-color: #ff6f1d;
}

.bg-orange-500 {
  background-color: #d04b00;
}

.bg-peach {
  background-color: #ffb083;
}

.bg-peach-100 {
  background-color: #fff4ed;
}

.bg-peach-100\/60 {
  background-color: #fff4ed99;
}

.bg-peach-200 {
  background-color: #ffe7d4;
}

.bg-peach-300 {
  background-color: #ffd3ac;
}

.bg-peach-400 {
  background-color: #ffb083;
}

.bg-peach-500 {
  background-color: #fd9d65;
}

.bg-pink-100 {
  background-color: #fbe8eb;
}

.bg-pink-100\/20 {
  background-color: #fbe8eb33;
}

.bg-pink-100\/60 {
  background-color: #fbe8eb99;
}

.bg-pink-300 {
  background-color: #efb2bd;
}

.bg-pink-400 {
  background-color: #e58499;
}

.bg-purple-100\/60 {
  background-color: #efecfb99;
}

.bg-red {
  background-color: #cc3939;
}

.bg-red-100 {
  background-color: #fee2e2;
}

.bg-red-100\/10 {
  background-color: #fee2e21a;
}

.bg-red-100\/40 {
  background-color: #fee2e266;
}

.bg-red-400 {
  background-color: #f87171;
}

.bg-red-500 {
  background-color: #cc3939;
}

.bg-slate-100\/60 {
  background-color: #d7e8ee99;
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  background-color: #fff;
}

.bg-white\/60 {
  background-color: #fff9;
}

.bg-white\/80 {
  background-color: #fffc;
}

.bg-white\/90 {
  background-color: #ffffffe6;
}

.bg-yellow-100 {
  background-color: #fef9c3;
}

.bg-yellow-100\/10 {
  background-color: #fef9c31a;
}

.bg-yellow-100\/40 {
  background-color: #fef9c366;
}

.bg-yellow-100\/60 {
  background-color: #fef9c399;
}

.bg-yellow-400 {
  background-color: #facc15;
}

.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}

.bg-none {
  background-image: none;
}

.from-red-500 {
  --tw-gradient-from: #cc3939 var(--tw-gradient-from-position);
  --tw-gradient-to: #cc393900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-white {
  --tw-gradient-from: #fff var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-red-300 {
  --tw-gradient-to: #fca5a5 var(--tw-gradient-to-position);
}

.to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.bg-\[length\:60px_60px\] {
  background-size: 60px 60px;
}

.bg-cover {
  background-size: cover;
}

.bg-bottom {
  background-position: bottom;
}

.bg-right-bottom {
  background-position: 100% 100%;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.\!p-0 {
  padding: 0 !important;
}

.p-0 {
  padding: 0;
}

.p-1 {
  padding: .25rem;
}

.p-1\.5 {
  padding: .375rem;
}

.p-2 {
  padding: .5rem;
}

.p-3 {
  padding: .75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.p-\[2px\] {
  padding: 2px;
}

.\!px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.\!py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-0 {
  padding-left: 0;
  padding-right: 0;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-1\.5 {
  padding-left: .375rem;
  padding-right: .375rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-2\.5 {
  padding-left: .625rem;
  padding-right: .625rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.\!pt-1 {
  padding-top: .25rem !important;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-1\.5 {
  padding-bottom: .375rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pb-px {
  padding-bottom: 1px;
}

.pe-0 {
  padding-inline-end: 0;
}

.pe-1 {
  padding-inline-end: .25rem;
}

.pe-2 {
  padding-inline-end: .5rem;
}

.pe-2\.5 {
  padding-inline-end: .625rem;
}

.pe-3 {
  padding-inline-end: .75rem;
}

.pe-4 {
  padding-inline-end: 1rem;
}

.pe-5 {
  padding-inline-end: 1.25rem;
}

.pe-8 {
  padding-inline-end: 2rem;
}

.pl-1 {
  padding-left: .25rem;
}

.pl-1\.5 {
  padding-left: .375rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pr-1 {
  padding-right: .25rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pr-2 {
  padding-right: .5rem;
}

.pr-2\.5 {
  padding-right: .625rem;
}

.pr-3 {
  padding-right: .75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.ps-0 {
  padding-inline-start: 0;
}

.ps-1 {
  padding-inline-start: .25rem;
}

.ps-2 {
  padding-inline-start: .5rem;
}

.ps-3 {
  padding-inline-start: .75rem;
}

.ps-4 {
  padding-inline-start: 1rem;
}

.ps-5 {
  padding-inline-start: 1.25rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-0\.5 {
  padding-top: .125rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-1\.5 {
  padding-top: .375rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-2 {
  padding-top: .5rem;
}

.pt-3 {
  padding-top: .75rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.\!text-right {
  text-align: right !important;
}

.text-right {
  text-align: right;
}

.text-start {
  text-align: start;
}

.text-end {
  text-align: end;
}

.align-baseline {
  vertical-align: baseline;
}

.align-top {
  vertical-align: top;
}

.\!align-middle {
  vertical-align: middle !important;
}

.align-middle {
  vertical-align: middle;
}

.align-bottom {
  vertical-align: bottom;
}

.font-display {
  font-family: Mont, ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.capitalize {
  text-transform: capitalize;
}

.normal-case {
  text-transform: none;
}

.italic {
  font-style: italic;
}

.leading-3 {
  line-height: .75rem;
}

.leading-4 {
  line-height: 1rem;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-9 {
  line-height: 2.25rem;
}

.leading-\[2\.6em\] {
  line-height: 2.6em;
}

.leading-\[35px\] {
  line-height: 35px;
}

.leading-none {
  line-height: 1;
}

.leading-normal {
  line-height: 1.5;
}

.leading-snug {
  line-height: 1.375;
}

.leading-tight {
  line-height: 1.25;
}

.\!text-black {
  color: #000 !important;
}

.\!text-gray-400 {
  color: #737373 !important;
}

.\!text-gray-500 {
  color: #3c3c3c !important;
}

.\!text-white {
  color: #fff !important;
}

.text-\[\#bac0c5\] {
  color: #bac0c5;
}

.text-\[var\(--tag-text\)\] {
  color: var(--tag-text);
}

.text-black {
  color: #000;
}

.text-blue, .text-blue-400 {
  color: #57acd9;
}

.text-blue-500 {
  color: #3192c6;
}

.text-fg-danger {
  color: #cc3939;
}

.text-fg-warning {
  color: #d97706;
}

.text-gray, .text-gray-300 {
  color: #cfcfcf;
}

.text-gray-400 {
  color: #737373;
}

.text-gray-500 {
  color: #3c3c3c;
}

.text-green, .text-green-400 {
  color: #41a048;
}

.text-green-500 {
  color: #338639;
}

.text-orange, .text-orange-400 {
  color: #ff6f1d;
}

.text-orange-500 {
  color: #d04b00;
}

.text-peach {
  color: #ffb083;
}

.text-peach-300 {
  color: #ffd3ac;
}

.text-peach-500 {
  color: #fd9d65;
}

.text-pink {
  color: #e58499;
}

.text-pink-500 {
  color: #d85b7a;
}

.text-red, .text-red-500 {
  color: #cc3939;
}

.text-status-complete {
  color: #338639;
}

.text-status-error {
  color: #cc3939;
}

.text-status-queued {
  color: #737373;
}

.text-status-started {
  color: #57acd9;
}

.text-status-stopped {
  color: #ff6f1d;
}

.text-vh-main-light-apricot {
  color: #ffd3ac;
}

.text-vh-sec-very-light-gray {
  color: #cfcfcf;
}

.text-white {
  color: #fff;
}

.text-yellow, .text-yellow-400 {
  color: #facc15;
}

.text-yellow-500 {
  color: #d97706;
}

.underline {
  text-decoration-line: underline;
}

.line-through {
  text-decoration-line: line-through;
}

.\!no-underline {
  text-decoration-line: none !important;
}

.no-underline {
  text-decoration-line: none;
}

.decoration-blue-500 {
  text-decoration-color: #3192c6;
}

.decoration-current {
  text-decoration-color: currentColor;
}

.decoration-gray-400 {
  text-decoration-color: #737373;
}

.decoration-gray-500 {
  text-decoration-color: #3c3c3c;
}

.decoration-white {
  text-decoration-color: #fff;
}

.decoration-dotted {
  text-decoration-style: dotted;
}

.decoration-1 {
  text-decoration-thickness: 1px;
}

.underline-offset-4 {
  text-underline-offset: 4px;
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.opacity-50 {
  opacity: .5;
}

.opacity-60 {
  opacity: .6;
}

.opacity-75 {
  opacity: .75;
}

.opacity-80 {
  opacity: .8;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px -1px #0000001a;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px #00000040;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-sm {
  --tw-shadow: 0 1px 2px 0 #0000000d;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.outline {
  outline-style: solid;
}

.outline-1 {
  outline-width: 1px;
}

.-outline-offset-1 {
  outline-offset: -1px;
}

.outline-offset-4 {
  outline-offset: 4px;
}

.outline-gray {
  outline-color: #cfcfcf;
}

.outline-gray-200 {
  outline-color: #e5e5e5;
}

.outline-gray-300 {
  outline-color: #cfcfcf;
}

.outline-red-500 {
  outline-color: #cc3939;
}

.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.ring-blue-400 {
  --tw-ring-color: #57acd9;
}

.ring-peach\/40 {
  --tw-ring-color: #ffb08366;
}

.ring-white {
  --tw-ring-color: #fff;
}

.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}

.ring-offset-black {
  --tw-ring-offset-color: #000;
}

.blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\!brightness-100 {
  --tw-brightness: brightness(1) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.brightness-95 {
  --tw-brightness: brightness(.95);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.sepia {
  --tw-sepia: sepia(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.\!filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[opacity\,transform\] {
  transition-property: opacity, transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-\[width\] {
  transition-property: width;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-opacity {
  transition-property: opacity;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition-transform {
  transition-property: transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-1000 {
  transition-duration: 1s;
}

.duration-150 {
  transition-duration: .15s;
}

.duration-200 {
  transition-duration: .2s;
}

.duration-300 {
  transition-duration: .3s;
}

.duration-500 {
  transition-duration: .5s;
}

.duration-75 {
  transition-duration: 75ms;
}

.ease-in-out {
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.ease-out {
  transition-timing-function: cubic-bezier(0, 0, .2, 1);
}

.will-change-transform {
  will-change: transform;
}

.scrollbar-thin::-webkit-scrollbar-track {
  background-color: var(--scrollbar-track);
  border-radius: var(--scrollbar-track-radius);
}

.scrollbar-thin::-webkit-scrollbar-track:hover {
  background-color: var(--scrollbar-track-hover, var(--scrollbar-track));
}

.scrollbar-thin::-webkit-scrollbar-track:active {
  background-color: var(--scrollbar-track-active, var(--scrollbar-track-hover, var(--scrollbar-track)));
}

.scrollbar-thin::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: var(--scrollbar-thumb-radius);
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-thumb-hover, var(--scrollbar-thumb));
}

.scrollbar-thin::-webkit-scrollbar-thumb:active {
  background-color: var(--scrollbar-thumb-active, var(--scrollbar-thumb-hover, var(--scrollbar-thumb)));
}

.scrollbar-thin::-webkit-scrollbar-corner {
  background-color: var(--scrollbar-corner);
  border-radius: var(--scrollbar-corner-radius);
}

.scrollbar-thin::-webkit-scrollbar-corner:hover {
  background-color: var(--scrollbar-corner-hover, var(--scrollbar-corner));
}

.scrollbar-thin::-webkit-scrollbar-corner:active {
  background-color: var(--scrollbar-corner-active, var(--scrollbar-corner-hover, var(--scrollbar-corner)));
}

.scrollbar-thin {
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb, initial) var(--scrollbar-track, initial);
}

.scrollbar-thin::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: block;
}

.scrollbar-track-gray-100 {
  --scrollbar-track: #f8f8f8 !important;
}

.scrollbar-thumb-gray-300 {
  --scrollbar-thumb: #cfcfcf !important;
}

.\[grid-area\:left-edge\] {
  grid-area: left-edge;
}

.\[grid-area\:mid\] {
  grid-area: mid;
}

.\[grid-area\:name\] {
  grid-area: name;
}

.\[grid-area\:right-edge\] {
  grid-area: right-edge;
}

.\[grid-template-areas\:\'left-edge_mid_right-edge\'\] {
  grid-template-areas: "left-edge mid right-edge";
}

code {
  overflow-wrap: break-word;
  color: #d85b7a;
  font-size: .875rem;
  line-height: 1.25rem;
}

input[type="range"] {
  appearance: auto;
}

.form-select {
  background-color: #fff;
  border-color: #0000;
  border-right-width: 8px;
  border-radius: .25rem;
  outline: 1px solid #cfcfcf;
  width: 100%;
  height: 42px;
  margin-top: 1px;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: block;
}

.form-select:where(.dark, .dark *) {
  background-color: #000;
}

.form-select {
  --tw-ring-color: #ffb08366;
}

.form-select:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline-color: #0000;
}

.form-select[disabled], .form-select:disabled {
  cursor: not-allowed;
  background-color: #e5e5e5;
}

.vh-select__control {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  width: 100% !important;
  min-height: 2.5rem !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  border-width: 1px !important;
  border-color: #cfcfcf !important;
  border-radius: .25rem !important;
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.dark .vh-select__input {
  color: #fff !important;
}

.dark .vh-select__control, .dark .vh-select__menu {
  background-color: #000;
}

.dark .vh-select__control--is-disabled {
  background-color: #1a1a1a;
  border-color: #404040;
}

.dark .vh-select__option:hover, .dark .vh-select__option--is-focused, .dark .vh-select__option--is-selected {
  background-color: #303030;
}

.dark .vh-select__single-value {
  color: #fff;
}

.dark .vh-select__multi-value {
  color: #fff;
  background-color: #303030;
}

.dark .vh-select__multi-value__label {
  color: #fff;
}

.form-control-color {
  width: 3rem;
  height: 1.5rem;
  padding: .25rem;
  display: block;
}

.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}

.form-control-color::-moz-color-swatch {
  border-width: 0;
  border-radius: .25rem;
}

.form-control-color::-webkit-color-swatch {
  border-width: 0;
  border-radius: .25rem;
}

.is-invalid {
  border-width: 1px;
  border-color: #cc3939;
}

.is-valid {
  border-width: 1px;
  border-color: #41a048;
}

input[type="checkbox"] {
  accent-color: #ffb083;
  outline-offset: 2px;
  --tw-ring-color: #ffb083;
  --tw-ring-offset-width: 1px;
  outline: 2px solid #0000;
}

input[type="checkbox"]:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.table {
  background-color: #fff;
  width: 100%;
}

.table:where(.dark, .dark *) {
  background-color: #121212;
}

.table {
  vertical-align: top;
}

.table th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

.table > :not(caption) > * > * {
  border-bottom-width: 1px;
  padding: .5rem;
}

.table > tbody {
  vertical-align: inherit;
}

.table > thead {
  vertical-align: bottom;
}

.table-bordered {
  border-width: 1px;
  border-color: #cfcfcf;
  border-radius: .25rem;
  margin-bottom: .5rem;
}

.table-bordered:where(.dark, .dark *) {
  border-color: #303030;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: #f8f8f8;
}

.table-striped > tbody > tr:nth-of-type(odd) > :where(.dark, .dark *) {
  background-color: #1a1a1a;
}

@font-face {
  font-family: Mont;
  font-style: normal;
  font-weight: normal;
  src: url("mont-regular.3843e5f3.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: Mont;
  font-style: normal;
  font-weight: bold;
  src: url("mont-bold.b39593ef.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: Mont;
  font-style: italic;
  font-weight: bold;
  src: url("mont-bolditalic.0b9ff747.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: Mont;
  font-style: normal;
  font-weight: 500;
  src: url("mont-semibold.653acf5b.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: Mont;
  font-style: italic;
  font-weight: 500;
  src: url("mont-semibolditalic.85739ba5.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: Mont;
  font-style: italic;
  font-weight: normal;
  src: url("mont-regularitalic.9374a389.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  src: url("rubik-regular.af8281ac.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  src: url("rubik-semibold.a5752367.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: bold;
  src: url("rubik-semibolditalic.f1e8f771.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: normal;
  src: url("rubik-italic.a7a675e0.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: Rubik;
  font-style: normal;
  font-weight: 300;
  src: url("rubik-light.86ee8315.woff2") format("woff2");
  font-display: fallback;
}

@font-face {
  font-family: Rubik;
  font-style: italic;
  font-weight: 300;
  src: url("rubik-lightitalic.6e3bd4de.woff2") format("woff2");
  font-display: fallback;
}

.fa, .fab, .fad, .fal, .far, .fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-variant: normal;
  text-rendering: auto;
  font-style: normal;
  line-height: 1;
  display: inline-block;
}

.fa-lg {
  vertical-align: -.0667em;
  font-size: 1.33333em;
  line-height: .75em;
}

.fa-xs {
  font-size: .75em;
}

.fa-sm {
  font-size: .875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  margin-left: 2.5em;
  padding-left: 0;
  list-style-type: none;
}

.fa-ul > li {
  position: relative;
}

.fa-li {
  text-align: center;
  width: 2em;
  line-height: inherit;
  position: absolute;
  left: -2em;
}

.fa-border {
  border: .08em solid #eee;
  border-radius: .1em;
  padding: .2em .25em .15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left, .fab.fa-pull-left, .fal.fa-pull-left, .far.fa-pull-left, .fas.fa-pull-left {
  margin-right: .3em;
}

.fa.fa-pull-right, .fab.fa-pull-right, .fal.fa-pull-right, .far.fa-pull-right, .fas.fa-pull-right {
  margin-left: .3em;
}

.fa-spin {
  animation: 2s linear infinite fa-spin;
}

.fa-pulse {
  animation: 1s steps(8, end) infinite fa-spin;
}

@keyframes fa-spin {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scaleX(-1);
}

.fa-flip-vertical {
  transform: scaleY(-1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical, .fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  transform: scale(-1);
}

:root .fa-flip-both, :root .fa-flip-horizontal, :root .fa-flip-vertical, :root .fa-rotate-90, :root .fa-rotate-180, :root .fa-rotate-270 {
  filter: none;
}

.fa-stack {
  vertical-align: middle;
  width: 2.5em;
  height: 2em;
  line-height: 2em;
  display: inline-block;
  position: relative;
}

.fa-stack-1x, .fa-stack-2x {
  text-align: center;
  width: 100%;
  position: absolute;
  left: 0;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

.fa-address-card:before {
  content: "";
}

.fa-adjust:before {
  content: "";
}

.fa-angle-left:before {
  content: "";
}

.fa-arrow-alt-circle-up:before {
  content: "";
}

.fa-arrow-circle-down:before {
  content: "";
}

.fa-arrow-circle-up:before {
  content: "";
}

.fa-arrow-down:before {
  content: "";
}

.fa-arrow-right:before {
  content: "";
}

.fa-arrow-up:before {
  content: "";
}

.fa-asterisk:before {
  content: "";
}

.fa-backward:before {
  content: "";
}

.fa-balance-scale:before {
  content: "";
}

.fa-ban:before {
  content: "";
}

.fa-bars:before {
  content: "";
}

.fa-bell:before {
  content: "";
}

.fa-birthday-cake:before {
  content: "";
}

.fa-bitbucket:before {
  content: "";
}

.fa-bolt:before {
  content: "";
}

.fa-book:before {
  content: "";
}

.fa-book-open:before {
  content: "";
}

.fa-books:before {
  content: "";
}

.fa-brain:before {
  content: "";
}

.fa-bug:before {
  content: "";
}

.fa-building:before {
  content: "";
}

.fa-bullhorn:before {
  content: "";
}

.fa-bullseye:before {
  content: "";
}

.fa-calculator-alt:before {
  content: "";
}

.fa-caret-down:before {
  content: "";
}

.fa-caret-up:before {
  content: "";
}

.fa-chart-bar:before {
  content: "";
}

.fa-chart-line:before {
  content: "";
}

.fa-chart-network:before {
  content: "";
}

.fa-check:before {
  content: "";
}

.fa-check-circle:before {
  content: "";
}

.fa-chevron-left:before {
  content: "";
}

.fa-chevron-right:before {
  content: "";
}

.fa-circle:before {
  content: "";
}

.fa-circle-notch:before {
  content: "";
}

.fa-clipboard:before {
  content: "";
}

.fa-clipboard-check:before {
  content: "";
}

.fa-clock:before {
  content: "";
}

.fa-cloud-upload-alt:before {
  content: "";
}

.fa-code-commit:before {
  content: "";
}

.fa-cog:before {
  content: "";
}

.fa-cogs:before {
  content: "";
}

.fa-columns:before {
  content: "";
}

.fa-comments-alt:before {
  content: "";
}

.fa-copy:before {
  content: "";
}

.fa-credit-card:before {
  content: "";
}

.fa-cube:before {
  content: "";
}

.fa-cubes:before {
  content: "";
}

.fa-database:before {
  content: "";
}

.fa-dollar-sign:before {
  content: "";
}

.fa-door-open:before {
  content: "";
}

.fa-dot-circle:before {
  content: "";
}

.fa-download:before {
  content: "";
}

.fa-edit:before {
  content: "";
}

.fa-ellipsis-h:before {
  content: "";
}

.fa-ellipsis-v:before {
  content: "";
}

.fa-envelope:before {
  content: "";
}

.fa-eraser:before {
  content: "";
}

.fa-exchange:before {
  content: "";
}

.fa-exclamation:before {
  content: "";
}

.fa-exclamation-circle:before {
  content: "";
}

.fa-exclamation-triangle:before {
  content: "";
}

.fa-expand-arrows:before {
  content: "";
}

.fa-external-link-alt:before {
  content: "";
}

.fa-eye:before {
  content: "";
}

.fa-eye-slash:before {
  content: "";
}

.fa-fast-backward:before {
  content: "";
}

.fa-fast-forward:before {
  content: "";
}

.fa-file:before {
  content: "";
}

.fa-file-alt:before {
  content: "";
}

.fa-file-export:before {
  content: "";
}

.fa-file-import:before {
  content: "";
}

.fa-filter:before {
  content: "";
}

.fa-flask:before {
  content: "";
}

.fa-folder-tree:before {
  content: "";
}

.fa-forward:before {
  content: "";
}

.fa-github:before {
  content: "";
}

.fa-gitlab:before {
  content: "";
}

.fa-glasses:before {
  content: "";
}

.fa-globe:before {
  content: "";
}

.fa-greater-than:before {
  content: "";
}

.fa-hand-holding-usd:before {
  content: "";
}

.fa-hand-paper:before {
  content: "";
}

.fa-hand-peace:before {
  content: "";
}

.fa-hand-point-right:before {
  content: "";
}

.fa-handshake:before {
  content: "";
}

.fa-hdd:before {
  content: "";
}

.fa-history:before {
  content: "";
}

.fa-hourglass-half:before {
  content: "";
}

.fa-image:before {
  content: "";
}

.fa-images:before {
  content: "";
}

.fa-inbox:before {
  content: "";
}

.fa-info-circle:before {
  content: "";
}

.fa-key:before {
  content: "";
}

.fa-layer-group:before {
  content: "";
}

.fa-less-than:before {
  content: "";
}

.fa-life-ring:before {
  content: "";
}

.fa-lightbulb:before {
  content: "";
}

.fa-link:before {
  content: "";
}

.fa-list:before {
  content: "";
}

.fa-list-ol:before {
  content: "";
}

.fa-lock:before {
  content: "";
}

.fa-microchip:before {
  content: "";
}

.fa-microsoft:before {
  content: "";
}

.fa-minus:before {
  content: "";
}

.fa-moon:before {
  content: "";
}

.fa-palette:before {
  content: "";
}

.fa-paragraph:before {
  content: "";
}

.fa-pause-circle:before {
  content: "";
}

.fa-pen:before {
  content: "";
}

.fa-play:before {
  content: "";
}

.fa-play-circle:before {
  content: "";
}

.fa-plus:before {
  content: "";
}

.fa-power-off:before {
  content: "";
}

.fa-print:before {
  content: "";
}

.fa-project-diagram:before {
  content: "";
}

.fa-question:before {
  content: "";
}

.fa-random:before {
  content: "";
}

.fa-recycle:before {
  content: "";
}

.fa-repeat-alt:before {
  content: "";
}

.fa-rocket:before {
  content: "";
}

.fa-save:before {
  content: "";
}

.fa-search:before {
  content: "";
}

.fa-server:before {
  content: "";
}

.fa-shield-alt:before {
  content: "";
}

.fa-sign-in-alt:before {
  content: "";
}

.fa-sign-out-alt:before {
  content: "";
}

.fa-sort-alpha-down:before {
  content: "";
}

.fa-sort-amount-down:before {
  content: "";
}

.fa-spinner:before {
  content: "";
}

.fa-star:before {
  content: "";
}

.fa-step-forward:before {
  content: "";
}

.fa-sticky-note:before {
  content: "";
}

.fa-stop-circle:before {
  content: "";
}

.fa-stopwatch:before {
  content: "";
}

.fa-sync:before {
  content: "";
}

.fa-tachometer:before {
  content: "";
}

.fa-tag:before {
  content: "";
}

.fa-th-large:before {
  content: "";
}

.fa-thumbs-down:before {
  content: "";
}

.fa-thumbs-up:before {
  content: "";
}

.fa-thumbtack:before {
  content: "";
}

.fa-ticket-alt:before {
  content: "";
}

.fa-times:before {
  content: "";
}

.fa-times-circle:before {
  content: "";
}

.fa-tools:before {
  content: "";
}

.fa-trash:before {
  content: "";
}

.fa-undo:before {
  content: "";
}

.fa-university:before {
  content: "";
}

.fa-unlink:before {
  content: "";
}

.fa-unlock:before {
  content: "";
}

.fa-upload:before {
  content: "";
}

.fa-user:before {
  content: "";
}

.fa-user-plus:before {
  content: "";
}

.fa-users:before {
  content: "";
}

.fa-users-cog:before {
  content: "";
}

.fa-utensil-spoon:before {
  content: "";
}

.fa-wrench:before {
  content: "";
}

.sr-only {
  clip: rect(0, 0, 0, 0);
  border: 0;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  position: static;
  overflow: visible;
}

.fa-swap-opacity .fad:after, .fad.fa-swap-opacity:after {
  opacity: var(--fa-primary-opacity, 1);
}

.fal {
  font-weight: 300;
}

.far {
  font-weight: 400;
}

.fa, .fas {
  font-family: FontAwesome-Subset-Solid;
  font-weight: 400;
}

.far {
  font-family: FontAwesome-Subset-Regular;
  font-weight: 400;
}

.fab {
  font-family: FontAwesome-Subset-Brands;
  font-weight: 400;
}

@font-face {
  font-family: FontAwesome-Subset-Brands;
  font-style: normal;
  font-weight: 400;
  src: url("fa-subset-brands.cfd4a914.woff2") format("woff2");
}

@font-face {
  font-family: FontAwesome-Subset-Solid;
  font-style: normal;
  font-weight: 400;
  src: url("fa-subset-solid.763e7caf.woff2") format("woff2");
}

@font-face {
  font-family: FontAwesome-Subset-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("fa-subset-regular.a942db91.woff2") format("woff2");
}

.status-bg-created {
  background: #cfcfcf;
}

.status-text-created {
  color: #cfcfcf;
  border-color: #cfcfcf;
}

.status-bg-queued {
  background: #737373;
}

.status-text-queued {
  color: #737373;
  border-color: #737373;
}

.status-bg-started {
  background: #57acd9;
}

.status-text-started {
  color: #57acd9;
  border-color: #57acd9;
}

.status-bg-complete {
  background: #338639;
}

.status-text-complete {
  color: #338639;
  border-color: #338639;
}

.status-bg-error {
  background: #cc3939;
}

.status-text-error {
  color: #cc3939;
  border-color: #cc3939;
}

.status-bg-stopping {
  background: #ff8107;
}

.status-text-stopping {
  color: #ff8107;
  border-color: #ff8107;
}

.status-bg-stopping_hard {
  background: #ff8107;
}

.status-text-stopping_hard {
  color: #ff8107;
  border-color: #ff8107;
}

.status-bg-stopped {
  background: #ff6f1d;
}

.status-text-stopped {
  color: #ff6f1d;
  border-color: #ff6f1d;
}

body.preauth-page {
  background-color: #ffe4c9;
  background-image: url("login-bg.7a4a7e21.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

body.preauth-page .card {
  background-color: #f8f8f8;
}

body.signup-page {
  background-color: #ffe4c9;
  background-image: url("signup_bg_pioneer.7d0e1490.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

body.signup-page .card {
  background-color: #f8f8f8;
}

body {
  overflow: scroll;
}

@keyframes vh-hand-wave-animation {
  0% {
    transform-origin: 60% 100%;
    transform: rotate(0);
  }

  10% {
    transform: rotate(-16deg);
  }

  20% {
    transform: rotate(6deg);
  }

  30% {
    transform: rotate(-16deg);
  }

  40% {
    transform: rotate(4deg);
  }

  50% {
    transform: rotate(-16deg);
  }

  60% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(0);
  }
}

@keyframes fade-in-down-subtle {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.invitation-heading {
  max-width: 36rem;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
}

.invitation-subheading {
  max-width: 28rem;
  margin-left: auto;
  margin-right: auto;
  font-weight: 400;
}

table.tablor-table.freeze-title-column th:first-child, table.tablor-table.freeze-title-column td:first-child {
  background-clip: padding-box;
  padding-left: 0;
  padding-right: 20px;
  position: sticky;
  left: 0;
}

table.tablor-table.freeze-title-column tbody tr {
  background-color: #fff;
}

table.tablor-table.freeze-title-column tbody tr:where(.dark, .dark *) {
  background-color: #121212;
}

table.tablor-table.freeze-title-column tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8;
}

table.tablor-table.freeze-title-column tbody tr:nth-of-type(odd):where(.dark, .dark *) {
  background-color: #121212;
}

table.tablor-table.freeze-title-column tbody tr th:first-child:after, table.tablor-table.freeze-title-column tbody tr td:first-child:after, table.tablor-table.freeze-title-column thead tr th:first-child:after, table.tablor-table.freeze-title-column thead tr td:first-child:after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  position: absolute;
  inset: 0 0 0 calc(100% - 12px);
}

table.tablor-table.freeze-title-column tbody tr th:first-child:where(.dark, .dark *):after, table.tablor-table.freeze-title-column tbody tr td:first-child:where(.dark, .dark *):after, table.tablor-table.freeze-title-column thead tr th:first-child:where(.dark, .dark *):after, table.tablor-table.freeze-title-column thead tr td:first-child:where(.dark, .dark *):after {
  content: var(--tw-content);
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

table.tablor-table.freeze-title-column tbody tr.tablor-selected-row, table.tablor-table.freeze-title-column thead tr.tablor-selected-row {
  background-color: #fff4ed;
}

table.tablor-table.freeze-title-column tbody tr.tablor-selected-row:where(.dark, .dark *), table.tablor-table.freeze-title-column thead tr.tablor-selected-row:where(.dark, .dark *) {
  background-color: #1a1a1a;
}

table.tablor-table.freeze-title-column tbody tr.tablor-selected-row th:first-child:after, table.tablor-table.freeze-title-column tbody tr.tablor-selected-row td:first-child:after, table.tablor-table.freeze-title-column thead tr.tablor-selected-row th:first-child:after, table.tablor-table.freeze-title-column thead tr.tablor-selected-row td:first-child:after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  position: absolute;
  inset: 0 0 0 calc(100% - 12px);
}

table.tablor-table.freeze-title-column tbody tr.tablor-selected-row th:first-child:where(.dark, .dark *):after, table.tablor-table.freeze-title-column tbody tr.tablor-selected-row td:first-child:where(.dark, .dark *):after, table.tablor-table.freeze-title-column thead tr.tablor-selected-row th:first-child:where(.dark, .dark *):after, table.tablor-table.freeze-title-column thead tr.tablor-selected-row td:first-child:where(.dark, .dark *):after {
  content: var(--tw-content);
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

table.tablor-table.freeze-title-column tbody tr.tablor-highlighted-row, table.tablor-table.freeze-title-column thead tr.tablor-highlighted-row {
  background-color: #fff4ed;
  outline-width: 2px;
  outline-color: #ff6f1d;
}

table.tablor-table.freeze-title-column tbody tr.tablor-highlighted-row:where(.dark, .dark *), table.tablor-table.freeze-title-column thead tr.tablor-highlighted-row:where(.dark, .dark *) {
  background-color: #d04b00;
}

table.tablor-table.freeze-title-column tbody tr.tablor-highlighted-row, table.tablor-table.freeze-title-column thead tr.tablor-highlighted-row {
  position: relative;
}

table.tablor-table.freeze-title-column tbody tr.tablor-highlighted-row th:first-child:after, table.tablor-table.freeze-title-column tbody tr.tablor-highlighted-row td:first-child:after, table.tablor-table.freeze-title-column thead tr.tablor-highlighted-row th:first-child:after, table.tablor-table.freeze-title-column thead tr.tablor-highlighted-row td:first-child:after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  position: absolute;
  inset: 0 0 0 calc(100% - 12px);
}

table.tablor-table.freeze-title-column tbody tr.tablor-highlighted-row th:first-child:where(.dark, .dark *):after, table.tablor-table.freeze-title-column tbody tr.tablor-highlighted-row td:first-child:where(.dark, .dark *):after, table.tablor-table.freeze-title-column thead tr.tablor-highlighted-row th:first-child:where(.dark, .dark *):after, table.tablor-table.freeze-title-column thead tr.tablor-highlighted-row td:first-child:where(.dark, .dark *):after {
  content: var(--tw-content);
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

table.tablor-table.freeze-title-column tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}

table.tablor-table.freeze-title-column tbody tr:nth-child(odd):where(.dark, .dark *) {
  background-color: #121212;
}

table.tablor-table.freeze-title-column tbody tr:nth-child(odd) th:first-child:after, table.tablor-table.freeze-title-column tbody tr:nth-child(odd) td:first-child:after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  position: absolute;
  inset: 0 0 0 calc(100% - 12px);
}

table.tablor-table.freeze-title-column tbody tr:nth-child(odd) th:first-child:where(.dark, .dark *):after, table.tablor-table.freeze-title-column tbody tr:nth-child(odd) td:first-child:where(.dark, .dark *):after {
  content: var(--tw-content);
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

table.tablor-table.freeze-title-column tbody tr:nth-child(odd).tablor-selected-row {
  background-color: #ffe7d4;
}

table.tablor-table.freeze-title-column tbody tr:nth-child(odd).tablor-selected-row:where(.dark, .dark *) {
  background-color: #1a1a1a;
}

table.tablor-table.freeze-title-column tbody tr:nth-child(odd).tablor-selected-row th:first-child:after, table.tablor-table.freeze-title-column tbody tr:nth-child(odd).tablor-selected-row td:first-child:after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  position: absolute;
  inset: 0 0 0 calc(100% - 12px);
}

table.tablor-table.freeze-title-column tbody tr:nth-child(odd).tablor-selected-row th:first-child:where(.dark, .dark *):after, table.tablor-table.freeze-title-column tbody tr:nth-child(odd).tablor-selected-row td:first-child:where(.dark, .dark *):after {
  content: var(--tw-content);
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

table.tablor-table.freeze-title-column tbody tr:nth-child(odd).tablor-highlighted-row {
  background-color: #ffe7d4;
}

table.tablor-table.freeze-title-column tbody tr:nth-child(odd).tablor-highlighted-row:where(.dark, .dark *) {
  background-color: #d04b00;
}

table.tablor-table.freeze-title-column tbody tr:nth-child(odd).tablor-highlighted-row th:first-child:after, table.tablor-table.freeze-title-column tbody tr:nth-child(odd).tablor-highlighted-row td:first-child:after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  position: absolute;
  inset: 0 0 0 calc(100% - 12px);
}

table.tablor-table.freeze-title-column tbody tr:nth-child(odd).tablor-highlighted-row th:first-child:where(.dark, .dark *):after, table.tablor-table.freeze-title-column tbody tr:nth-child(odd).tablor-highlighted-row td:first-child:where(.dark, .dark *):after {
  content: var(--tw-content);
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

table.tablor-table.freeze-title-column-with-selection th:first-child, table.tablor-table.freeze-title-column-with-selection td:first-child {
  background-clip: padding-box;
  background-color: inherit;
  min-width: 35px;
  max-width: 35px;
  padding-left: 0;
  padding-right: 0;
  position: sticky;
  left: 0;
}

table.tablor-table.freeze-title-column-with-selection tbody tr {
  background-color: #fff;
}

table.tablor-table.freeze-title-column-with-selection tbody tr:where(.dark, .dark *) {
  background-color: #121212;
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8;
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-of-type(odd):where(.dark, .dark *) {
  background-color: #121212;
}

table.tablor-table.freeze-title-column-with-selection tbody tr th:nth-child(2):after, table.tablor-table.freeze-title-column-with-selection tbody tr td:nth-child(2):after, table.tablor-table.freeze-title-column-with-selection thead tr th:nth-child(2):after, table.tablor-table.freeze-title-column-with-selection thead tr td:nth-child(2):after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  position: absolute;
  inset: 0 0 0 calc(100% - 12px);
}

table.tablor-table.freeze-title-column-with-selection tbody tr th:nth-child(2):where(.dark, .dark *):after, table.tablor-table.freeze-title-column-with-selection tbody tr td:nth-child(2):where(.dark, .dark *):after, table.tablor-table.freeze-title-column-with-selection thead tr th:nth-child(2):where(.dark, .dark *):after, table.tablor-table.freeze-title-column-with-selection thead tr td:nth-child(2):where(.dark, .dark *):after {
  content: var(--tw-content);
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-selected-row, table.tablor-table.freeze-title-column-with-selection thead tr.tablor-selected-row {
  background-color: #fff4ed;
}

table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-selected-row:where(.dark, .dark *), table.tablor-table.freeze-title-column-with-selection thead tr.tablor-selected-row:where(.dark, .dark *) {
  background-color: #1a1a1a;
}

table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-selected-row th:nth-child(2):after, table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-selected-row td:nth-child(2):after, table.tablor-table.freeze-title-column-with-selection thead tr.tablor-selected-row th:nth-child(2):after, table.tablor-table.freeze-title-column-with-selection thead tr.tablor-selected-row td:nth-child(2):after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  position: absolute;
  inset: 0 0 0 calc(100% - 12px);
}

table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-selected-row th:nth-child(2):where(.dark, .dark *):after, table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-selected-row td:nth-child(2):where(.dark, .dark *):after, table.tablor-table.freeze-title-column-with-selection thead tr.tablor-selected-row th:nth-child(2):where(.dark, .dark *):after, table.tablor-table.freeze-title-column-with-selection thead tr.tablor-selected-row td:nth-child(2):where(.dark, .dark *):after {
  content: var(--tw-content);
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-highlighted-row, table.tablor-table.freeze-title-column-with-selection thead tr.tablor-highlighted-row {
  background-color: #fff4ed;
  outline-width: 2px;
  outline-color: #ff6f1d;
}

table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-highlighted-row:where(.dark, .dark *), table.tablor-table.freeze-title-column-with-selection thead tr.tablor-highlighted-row:where(.dark, .dark *) {
  background-color: #d04b00;
}

table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-highlighted-row, table.tablor-table.freeze-title-column-with-selection thead tr.tablor-highlighted-row {
  position: relative;
}

table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-highlighted-row th:nth-child(2):after, table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-highlighted-row td:nth-child(2):after, table.tablor-table.freeze-title-column-with-selection thead tr.tablor-highlighted-row th:nth-child(2):after, table.tablor-table.freeze-title-column-with-selection thead tr.tablor-highlighted-row td:nth-child(2):after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  position: absolute;
  inset: 0 0 0 calc(100% - 12px);
}

table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-highlighted-row th:nth-child(2):where(.dark, .dark *):after, table.tablor-table.freeze-title-column-with-selection tbody tr.tablor-highlighted-row td:nth-child(2):where(.dark, .dark *):after, table.tablor-table.freeze-title-column-with-selection thead tr.tablor-highlighted-row th:nth-child(2):where(.dark, .dark *):after, table.tablor-table.freeze-title-column-with-selection thead tr.tablor-highlighted-row td:nth-child(2):where(.dark, .dark *):after {
  content: var(--tw-content);
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd) {
  background-color: #f8f8f8;
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd):where(.dark, .dark *) {
  background-color: #121212;
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd) th:nth-child(2):after, table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd) td:nth-child(2):after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  position: absolute;
  inset: 0 0 0 calc(100% - 12px);
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd) th:nth-child(2):where(.dark, .dark *):after, table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd) td:nth-child(2):where(.dark, .dark *):after {
  content: var(--tw-content);
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd).tablor-selected-row {
  background-color: #ffe7d4;
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd).tablor-selected-row:where(.dark, .dark *) {
  background-color: #1a1a1a;
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd).tablor-selected-row th:nth-child(2):after, table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd).tablor-selected-row td:nth-child(2):after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  position: absolute;
  inset: 0 0 0 calc(100% - 12px);
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd).tablor-selected-row th:nth-child(2):where(.dark, .dark *):after, table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd).tablor-selected-row td:nth-child(2):where(.dark, .dark *):after {
  content: var(--tw-content);
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd).tablor-highlighted-row {
  background-color: #ffe7d4;
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd).tablor-highlighted-row:where(.dark, .dark *) {
  background-color: #d04b00;
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd).tablor-highlighted-row th:nth-child(2):after, table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd).tablor-highlighted-row td:nth-child(2):after {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: #0000001a var(--tw-gradient-from-position);
  --tw-gradient-to: transparent var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  content: var(--tw-content);
  position: absolute;
  inset: 0 0 0 calc(100% - 12px);
}

table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd).tablor-highlighted-row th:nth-child(2):where(.dark, .dark *):after, table.tablor-table.freeze-title-column-with-selection tbody tr:nth-child(odd).tablor-highlighted-row td:nth-child(2):where(.dark, .dark *):after {
  content: var(--tw-content);
  --tw-gradient-from: #ffffff40 var(--tw-gradient-from-position);
  --tw-gradient-to: #fff0 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

table.tablor-table.freeze-title-column-with-selection th:nth-child(2), table.tablor-table.freeze-title-column-with-selection td:nth-child(2) {
  padding-left: 0;
  padding-right: 20px;
  position: sticky;
  left: 35px;
}

.table-ordering-icon {
  -webkit-appearance: none;
  cursor: pointer;
  color: #cfcfcf;
  background: none;
  border: none;
  line-height: .5;
  text-decoration: none;
}

.table-ordering-icon.active {
  color: #fd9d65;
}

.table-ordering-button {
  -webkit-appearance: none;
  cursor: pointer;
  color: #cfcfcf;
  background: none;
  border: none;
  padding-left: .5rem;
  padding-right: .5rem;
  text-decoration: none;
}

div.tablor-split-panel {
  grid-gap: 0;
  grid-template-columns: 1fr 44px;
  display: grid;
}

div.tablor-split-panel.expanded {
  grid-template-columns: 1fr 40%;
}

.tablor-table > tbody tr:nth-of-type(2n) {
  background-color: #fff;
}

.tablor-table > tbody tr:nth-of-type(2n):where(.dark, .dark *) {
  background-color: #121212;
}

.tablor-table > tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8;
}

.tablor-table > tbody tr:nth-of-type(odd):where(.dark, .dark *) {
  background-color: #121212;
}

.rta {
  width: 100%;
  height: 100%;
  position: relative;
}

.rta__loader.rta__loader--empty-suggestion-data {
  border-radius: 3px;
  padding: 5px;
  box-shadow: 0 0 5px #1b1f231a;
}

.rta--loading .rta__loader.rta__loader--suggestion-data {
  background: #fffc;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.rta--loading .rta__loader.rta__loader--suggestion-data > * {
  position: relative;
  top: 50%;
}

.rta__textarea {
  width: 100%;
  height: 100%;
  font-size: 1em;
}

.rta__autocomplete {
  margin-top: 1em;
  display: block;
  position: absolute;
}

.rta__autocomplete--top {
  margin-top: 0;
  margin-bottom: 1em;
}

.rta__list {
  background: #fff;
  border: 1px solid #e5e1df;
  border-radius: 3px;
  margin: 0;
  padding: 0;
  list-style: none;
  box-shadow: 0 0 5px #1b1f231a;
}

.rta__entity {
  text-align: left;
  outline: none;
  width: 100%;
}

.rta__entity:hover {
  cursor: pointer;
}

.rta__entity > * {
  padding-left: 4px;
  padding-right: 4px;
}

.rta__item:not(:last-child) {
  border-bottom: 1px solid #dfe2e5;
}

.rta__item--selected {
  color: #fff;
  background: #d65002;
  text-decoration: none;
}

.react-flow__container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.react-flow__pane {
  z-index: 1;
  cursor: -webkit-grab;
  cursor: grab;
}

.react-flow__pane.selection {
  cursor: pointer;
}

.react-flow__pane.dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.react-flow__viewport {
  transform-origin: 0 0;
  z-index: 2;
  pointer-events: none;
}

.react-flow__renderer {
  z-index: 4;
}

.react-flow__selection {
  z-index: 6;
}

.react-flow__nodesselection-rect:focus, .react-flow__nodesselection-rect:focus-visible {
  outline: none;
}

.react-flow .react-flow__edges {
  pointer-events: none;
  overflow: visible;
}

.react-flow__edge-path, .react-flow__connection-path {
  stroke: #b1b1b7;
  stroke-width: 1px;
  fill: none;
}

.react-flow__edge {
  pointer-events: visibleStroke;
  cursor: pointer;
}

.react-flow__edge.animated path {
  stroke-dasharray: 5;
  animation: .5s linear infinite dashdraw;
}

.react-flow__edge.animated path.react-flow__edge-interaction {
  stroke-dasharray: none;
  animation: none;
}

.react-flow__edge.inactive {
  pointer-events: none;
}

.react-flow__edge.selected, .react-flow__edge:focus, .react-flow__edge:focus-visible {
  outline: none;
}

.react-flow__edge.selected .react-flow__edge-path, .react-flow__edge:focus .react-flow__edge-path, .react-flow__edge:focus-visible .react-flow__edge-path {
  stroke: #555;
}

.react-flow__edge-textwrapper {
  pointer-events: all;
}

.react-flow__edge-textbg {
  fill: #fff;
}

.react-flow__edge .react-flow__edge-text {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
}

.react-flow__connection {
  pointer-events: none;
}

.react-flow__connection .animated {
  stroke-dasharray: 5;
  animation: .5s linear infinite dashdraw;
}

.react-flow__connectionline {
  z-index: 1001;
}

.react-flow__nodes {
  pointer-events: none;
  transform-origin: 0 0;
}

.react-flow__node {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: all;
  transform-origin: 0 0;
  box-sizing: border-box;
  cursor: -webkit-grab;
  cursor: grab;
  position: absolute;
}

.react-flow__node.dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.react-flow__nodesselection {
  z-index: 3;
  transform-origin: 0 0;
  pointer-events: none;
}

.react-flow__nodesselection-rect {
  pointer-events: all;
  cursor: -webkit-grab;
  cursor: grab;
  position: absolute;
}

.react-flow__handle {
  pointer-events: none;
  background: #1a192b;
  border: 1px solid #fff;
  border-radius: 100%;
  width: 6px;
  min-width: 5px;
  height: 6px;
  min-height: 5px;
  position: absolute;
}

.react-flow__handle.connectionindicator {
  pointer-events: all;
  cursor: crosshair;
}

.react-flow__handle-bottom {
  top: auto;
  bottom: -4px;
  left: 50%;
  transform: translate(-50%);
}

.react-flow__handle-top {
  top: -4px;
  left: 50%;
  transform: translate(-50%);
}

.react-flow__handle-left {
  top: 50%;
  left: -4px;
  transform: translate(0, -50%);
}

.react-flow__handle-right {
  top: 50%;
  right: -4px;
  transform: translate(0, -50%);
}

.react-flow__edgeupdater {
  cursor: move;
  pointer-events: all;
}

.react-flow__panel {
  z-index: 5;
  margin: 15px;
  position: absolute;
}

.react-flow__panel.top {
  top: 0;
}

.react-flow__panel.bottom {
  bottom: 0;
}

.react-flow__panel.left {
  left: 0;
}

.react-flow__panel.right {
  right: 0;
}

.react-flow__panel.center {
  left: 50%;
  transform: translateX(-50%);
}

.react-flow__attribution {
  background: #ffffff80;
  margin: 0;
  padding: 2px 3px;
  font-size: 10px;
}

.react-flow__attribution a {
  color: #999;
  text-decoration: none;
}

@keyframes dashdraw {
  from {
    stroke-dashoffset: 10px;
  }
}

.react-flow__edgelabel-renderer {
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
  height: 100%;
  position: absolute;
}

.react-flow__edge.updating .react-flow__edge-path {
  stroke: #777;
}

.react-flow__edge-text {
  font-size: 10px;
}

.react-flow__node.selectable:focus, .react-flow__node.selectable:focus-visible {
  outline: none;
}

.react-flow__node-default, .react-flow__node-input, .react-flow__node-output, .react-flow__node-group {
  color: #222;
  text-align: center;
  background-color: #fff;
  border: 1px solid #1a192b;
  border-radius: 3px;
  width: 150px;
  padding: 10px;
  font-size: 12px;
}

.react-flow__node-default.selectable:hover, .react-flow__node-input.selectable:hover, .react-flow__node-output.selectable:hover, .react-flow__node-group.selectable:hover {
  box-shadow: 0 1px 4px 1px #00000014;
}

.react-flow__node-default.selectable.selected, .react-flow__node-default.selectable:focus, .react-flow__node-default.selectable:focus-visible, .react-flow__node-input.selectable.selected, .react-flow__node-input.selectable:focus, .react-flow__node-input.selectable:focus-visible, .react-flow__node-output.selectable.selected, .react-flow__node-output.selectable:focus, .react-flow__node-output.selectable:focus-visible, .react-flow__node-group.selectable.selected, .react-flow__node-group.selectable:focus, .react-flow__node-group.selectable:focus-visible {
  box-shadow: 0 0 0 .5px #1a192b;
}

.react-flow__node-group {
  background-color: #f0f0f040;
}

.react-flow__nodesselection-rect, .react-flow__selection {
  background: #0059dc14;
  border: 1px dotted #0059dccc;
}

.react-flow__nodesselection-rect:focus, .react-flow__nodesselection-rect:focus-visible, .react-flow__selection:focus, .react-flow__selection:focus-visible {
  outline: none;
}

.react-flow__controls {
  box-shadow: 0 0 2px 1px #00000014;
}

.react-flow__controls-button {
  box-sizing: content-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background: #fefefe;
  border: none;
  border-bottom: 1px solid #eee;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 5px;
  display: flex;
}

.react-flow__controls-button:hover {
  background: #f4f4f4;
}

.react-flow__controls-button svg {
  width: 100%;
  max-width: 12px;
  max-height: 12px;
}

.react-flow__controls-button:disabled {
  pointer-events: none;
}

.react-flow__controls-button:disabled svg {
  fill-opacity: .4;
}

.react-flow__minimap {
  background-color: #fff;
}

.react-flow__resize-control {
  position: absolute;
}

.react-flow__resize-control.left, .react-flow__resize-control.right {
  cursor: ew-resize;
}

.react-flow__resize-control.top, .react-flow__resize-control.bottom {
  cursor: ns-resize;
}

.react-flow__resize-control.top.left, .react-flow__resize-control.bottom.right {
  cursor: nwse-resize;
}

.react-flow__resize-control.bottom.left, .react-flow__resize-control.top.right {
  cursor: nesw-resize;
}

.react-flow__resize-control.handle {
  background-color: #3367d9;
  border: 1px solid #fff;
  border-radius: 1px;
  width: 4px;
  height: 4px;
  transform: translate(-50%, -50%);
}

.react-flow__resize-control.handle.left {
  top: 50%;
  left: 0;
}

.react-flow__resize-control.handle.right {
  top: 50%;
  left: 100%;
}

.react-flow__resize-control.handle.top {
  top: 0;
  left: 50%;
}

.react-flow__resize-control.handle.bottom {
  top: 100%;
  left: 50%;
}

.react-flow__resize-control.handle.top.left, .react-flow__resize-control.handle.bottom.left {
  left: 0;
}

.react-flow__resize-control.handle.top.right, .react-flow__resize-control.handle.bottom.right {
  left: 100%;
}

.react-flow__resize-control.line {
  border: 0 solid #3367d9;
}

.react-flow__resize-control.line.left, .react-flow__resize-control.line.right {
  width: 1px;
  height: 100%;
  top: 0;
  transform: translate(-50%);
}

.react-flow__resize-control.line.left {
  border-left-width: 1px;
  left: 0;
}

.react-flow__resize-control.line.right {
  border-right-width: 1px;
  left: 100%;
}

.react-flow__resize-control.line.top, .react-flow__resize-control.line.bottom {
  width: 100%;
  height: 1px;
  left: 0;
  transform: translate(0, -50%);
}

.react-flow__resize-control.line.top {
  border-top-width: 1px;
  top: 0;
}

.react-flow__resize-control.line.bottom {
  border-bottom-width: 1px;
  top: 100%;
}

.js-plotly-plot .plotly .modebar svg {
  display: inline;
}

.dj-form input[type="text"], .dj-form input[type="email"], .dj-form input[type="password"], .dj-form textarea {
  outline-offset: 2px;
  --tw-ring-color: #ffb08366;
  background-color: #fff;
  border-width: 1px;
  border-color: #cfcfcf;
  border-radius: .25rem;
  outline: 2px solid #0000;
  width: 100%;
  min-height: 2.5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: block;
}

.dj-form input[type="text"]:focus, .dj-form input[type="email"]:focus, .dj-form input[type="password"]:focus, .dj-form textarea:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  border-color: #0000;
}

.dj-form input[type="submit"], .dj-form button {
  text-align: center;
  background-color: #ffb083;
  border-radius: .25rem;
  padding: .5rem 1rem;
}

.dj-form input[type="submit"]:hover, .dj-form button:hover {
  color: currentColor;
  --tw-brightness: brightness(1.05);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.dj-form select {
  --tw-ring-color: #ffb08366;
  background-color: #fff;
  border-color: #0000;
  border-right-width: 8px;
  border-radius: .25rem;
  outline: 1px solid #cfcfcf;
  width: 100%;
  height: 42px;
  margin-top: 1px;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .875rem;
  line-height: 1.25rem;
  display: block;
}

.dj-form select:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  outline-color: #0000;
}

.dj-form > p > label {
  margin-top: .5rem;
  display: block;
}

.dark\:prose-invert:where(.dark, .dark *) {
  --tw-prose-body: var(--tw-prose-invert-body);
  --tw-prose-headings: var(--tw-prose-invert-headings);
  --tw-prose-lead: var(--tw-prose-invert-lead);
  --tw-prose-links: var(--tw-prose-invert-links);
  --tw-prose-bold: var(--tw-prose-invert-bold);
  --tw-prose-counters: var(--tw-prose-invert-counters);
  --tw-prose-bullets: var(--tw-prose-invert-bullets);
  --tw-prose-hr: var(--tw-prose-invert-hr);
  --tw-prose-quotes: var(--tw-prose-invert-quotes);
  --tw-prose-quote-borders: var(--tw-prose-invert-quote-borders);
  --tw-prose-captions: var(--tw-prose-invert-captions);
  --tw-prose-kbd: var(--tw-prose-invert-kbd);
  --tw-prose-kbd-shadows: var(--tw-prose-invert-kbd-shadows);
  --tw-prose-code: var(--tw-prose-invert-code);
  --tw-prose-pre-code: var(--tw-prose-invert-pre-code);
  --tw-prose-pre-bg: var(--tw-prose-invert-pre-bg);
  --tw-prose-th-borders: var(--tw-prose-invert-th-borders);
  --tw-prose-td-borders: var(--tw-prose-invert-td-borders);
}

.\*\:-m-px > * {
  margin: -1px;
}

.\*\:w-full > * {
  width: 100%;
}

.\*\:rounded > * {
  border-radius: .25rem;
}

.\*\:border-b-0 > * {
  border-bottom-width: 0;
}

.\*\:bg-gray-200 > * {
  background-color: #e5e5e5;
}

.\*\:bg-pink-100 > * {
  background-color: #fbe8eb;
}

.\*\:stroke-blue-300 > * {
  stroke: #84c2e5;
}

.\*\:stroke-gray-300 > * {
  stroke: #cfcfcf;
}

.\*\:stroke-pink-300 > * {
  stroke: #efb2bd;
}

.\*\:p-2 > * {
  padding: .5rem;
}

.\*\:p-3 > * {
  padding: .75rem;
}

.\*\:align-middle > * {
  vertical-align: middle;
}

.after\:absolute:after {
  content: var(--tw-content);
  position: absolute;
}

.after\:start-\[2px\]:after {
  content: var(--tw-content);
  inset-inline-start: 2px;
}

.after\:top-\[2px\]:after {
  content: var(--tw-content);
  top: 2px;
}

.after\:size-2:after {
  content: var(--tw-content);
  width: .5rem;
  height: .5rem;
}

.after\:size-4:after {
  content: var(--tw-content);
  width: 1rem;
  height: 1rem;
}

.after\:size-5:after {
  content: var(--tw-content);
  width: 1.25rem;
  height: 1.25rem;
}

.after\:rounded-full:after {
  content: var(--tw-content);
  border-radius: 9999px;
}

.after\:border:after {
  content: var(--tw-content);
  border-width: 1px;
}

.after\:border-gray-200:after {
  content: var(--tw-content);
  border-color: #e5e5e5;
}

.after\:border-gray-300:after {
  content: var(--tw-content);
  border-color: #cfcfcf;
}

.after\:bg-gray-100:after {
  content: var(--tw-content);
  background-color: #f8f8f8;
}

.after\:bg-peach:after {
  content: var(--tw-content);
  background-color: #ffb083;
}

.after\:bg-white:after {
  content: var(--tw-content);
  background-color: #fff;
}

.after\:transition-all:after {
  content: var(--tw-content);
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.after\:duration-150:after {
  content: var(--tw-content);
  transition-duration: .15s;
}

.after\:ease-\[cubic-bezier\(\.2\,\.65\,\.45\,1\.45\)\]:after {
  content: var(--tw-content);
  transition-timing-function: cubic-bezier(.2, .65, .45, 1.45);
}

.after\:content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.after\:content-\[\'\*\'\]:after {
  --tw-content: "*";
  content: var(--tw-content);
}

.first\:ms-0:first-child {
  margin-inline-start: 0;
}

.last\:mb-0:last-child {
  margin-bottom: 0;
}

.last\:mb-3:last-child {
  margin-bottom: .75rem;
}

.last\:border-none:last-child {
  border-style: none;
}

.first-of-type\:rounded-l:first-of-type {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.first-of-type\:rounded-bl-md:first-of-type {
  border-bottom-left-radius: .375rem;
}

.last-of-type\:mr-0:last-of-type {
  margin-right: 0;
}

.last-of-type\:rounded-r:last-of-type {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem;
}

.last-of-type\:rounded-br-md:last-of-type {
  border-bottom-right-radius: .375rem;
}

.last-of-type\:border-e-0:last-of-type {
  border-inline-end-width: 0;
}

.group:last-child .group-last\:pb-0 {
  padding-bottom: 0;
}

.group:hover .group-hover\:bg-dark-300 {
  background-color: #252525;
}

.group:hover .group-hover\:bg-gray-100 {
  background-color: #f8f8f8;
}

.group:hover .group-hover\:text-black {
  color: #000;
}

.group:hover .group-hover\:underline {
  text-decoration-line: underline;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.group:hover .group-hover\:opacity-70 {
  opacity: .7;
}

.peer:first-child ~ .peer-first\:mt-1 {
  margin-top: .25rem;
}

.peer:checked ~ .peer-checked\:bg-peach-300 {
  background-color: #ffd3ac;
}

.peer:checked ~ .peer-checked\:bg-peach-500 {
  background-color: #fd9d65;
}

.peer:checked ~ .peer-checked\:after\:translate-x-full:after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.peer:checked ~ .peer-checked\:after\:border-white:after {
  content: var(--tw-content);
  border-color: #fff;
}

.peer:focus ~ .peer-focus\:outline-none {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.peer:focus ~ .peer-focus\:ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.peer:focus ~ .peer-focus\:ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.peer:focus ~ .peer-focus\:ring-peach\/40 {
  --tw-ring-color: #ffb08366;
}

.data-\[invalid\]\:border-red[data-invalid] {
  border-color: #cc3939;
}

.data-\[state\=active\]\:border-gray[data-state="active"] {
  border-color: #cfcfcf;
}

.data-\[state\=active\]\:border-b-white[data-state="active"] {
  border-bottom-color: #fff;
}

.data-\[checked\]\:bg-peach-300[data-checked] {
  background-color: #ffd3ac;
}

.data-\[checked\]\:bg-peach-500[data-checked] {
  background-color: #fd9d65;
}

.data-\[highlighted\]\:bg-gray-100[data-highlighted] {
  background-color: #f8f8f8;
}

.data-\[highlighted\]\:bg-green-200[data-highlighted] {
  background-color: #bbdfc1;
}

.data-\[highlighted\]\:bg-peach-400\/80[data-highlighted] {
  background-color: #ffb083cc;
}

.data-\[highlighted\]\:bg-red-200[data-highlighted] {
  background-color: #fecaca;
}

.data-\[state\=active\]\:bg-peach[data-state="active"] {
  background-color: #ffb083;
}

.data-\[highlighted\]\:text-gray-500[data-highlighted] {
  color: #3c3c3c;
}

.data-\[placeholder\]\:text-gray-400[data-placeholder] {
  color: #737373;
}

.data-\[state\=active\]\:text-black[data-state="active"] {
  color: #000;
}

.data-\[disabled\]\:opacity-50[data-disabled] {
  opacity: .5;
}

.data-\[highlighted\]\:outline-none[data-highlighted] {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.data-\[invalid\]\:outline-red[data-invalid] {
  outline-color: #cc3939;
}

.data-\[checked\]\:after\:translate-x-full[data-checked]:after {
  content: var(--tw-content);
  --tw-translate-x: 100%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.data-\[checked\]\:after\:border-white[data-checked]:after {
  content: var(--tw-content);
  border-color: #fff;
}

.prose-code\:before\:hidden :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):before, .prose-code\:after\:hidden :where(code):not(:where([class~="not-prose"], [class~="not-prose"] *)):after {
  content: var(--tw-content);
  display: none;
}

.hover\:cursor-pointer:hover {
  cursor: pointer;
}

.hover\:border-b-peach-300:hover {
  border-bottom-color: #ffd3ac;
}

.hover\:bg-\[\#f0d800\]:hover {
  background-color: #f0d800;
}

.hover\:bg-black:hover {
  background-color: #000;
}

.hover\:bg-gray-100:hover {
  background-color: #f8f8f8;
}

.hover\:bg-gray-100\/80:hover {
  background-color: #f8f8f8cc;
}

.hover\:bg-gray-200:hover {
  background-color: #e5e5e5;
}

.hover\:bg-gray-200\/50:hover {
  background-color: #e5e5e580;
}

.hover\:bg-gray-500:hover {
  background-color: #3c3c3c;
}

.hover\:bg-green-200:hover {
  background-color: #bbdfc1;
}

.hover\:bg-green-500:hover {
  background-color: #338639;
}

.hover\:bg-peach-300:hover {
  background-color: #ffd3ac;
}

.hover\:bg-red-200:hover {
  background-color: #fecaca;
}

.hover\:bg-red-500:hover {
  background-color: #cc3939;
}

.hover\:bg-slate-100\/40:hover {
  background-color: #d7e8ee66;
}

.hover\:bg-white:hover {
  background-color: #fff;
}

.hover\:bg-yellow-100\/80:hover {
  background-color: #fef9c3cc;
}

.hover\:text-black:hover {
  color: #000;
}

.hover\:text-blue-500:hover {
  color: #3192c6;
}

.hover\:text-current:hover {
  color: currentColor;
}

.hover\:text-gray-300:hover {
  color: #cfcfcf;
}

.hover\:text-gray-500:hover {
  color: #3c3c3c;
}

.hover\:text-green-500:hover {
  color: #338639;
}

.hover\:text-orange:hover, .hover\:text-orange-400:hover {
  color: #ff6f1d;
}

.hover\:text-peach-500:hover {
  color: #fd9d65;
}

.hover\:text-red-500:hover {
  color: #cc3939;
}

.hover\:text-white:hover {
  color: #fff;
}

.hover\:text-yellow-500:hover {
  color: #d97706;
}

.hover\:underline:hover {
  text-decoration-line: underline;
}

.hover\:no-underline:hover {
  text-decoration-line: none;
}

.hover\:opacity-80:hover {
  opacity: .8;
}

.hover\:ring:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.hover\:ring-peach:hover {
  --tw-ring-color: #ffb083;
}

.hover\:brightness-105:hover {
  --tw-brightness: brightness(1.05);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.hover\:brightness-95:hover {
  --tw-brightness: brightness(.95);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.data-\[state\=active\]\:hover\:bg-peach:hover[data-state="active"] {
  background-color: #ffb083;
}

.focus\:border-transparent:focus {
  border-color: #0000;
}

.focus\:bg-white:focus {
  background-color: #fff;
}

.focus\:opacity-70:focus {
  opacity: .7;
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:outline-transparent:focus {
  outline-color: #0000;
}

.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus\:ring-peach\/40:focus {
  --tw-ring-color: #ffb08366;
}

.active\:brightness-95:active {
  --tw-brightness: brightness(.95);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.disabled\:cursor-auto:disabled {
  cursor: auto;
}

.disabled\:cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:border-gray-300:disabled {
  border-color: #cfcfcf;
}

.disabled\:bg-gray-100:disabled {
  background-color: #f8f8f8;
}

.disabled\:bg-gray-200:disabled {
  background-color: #e5e5e5;
}

.disabled\:bg-gray-300:disabled {
  background-color: #cfcfcf;
}

.disabled\:text-gray-300:disabled {
  color: #cfcfcf;
}

.disabled\:text-gray-400:disabled {
  color: #737373;
}

.disabled\:hover\:no-underline:hover:disabled {
  text-decoration-line: none;
}

@media (width >= 640px) {
  .sm\:inline-block {
    display: inline-block;
  }

  .sm\:inline {
    display: inline;
  }

  .sm\:flex-row {
    flex-direction: row;
  }

  .sm\:px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

@media (width >= 768px) {
  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:block {
    display: block;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:gap-2 {
    gap: .5rem;
  }

  .md\:gap-8 {
    gap: 2rem;
  }

  .md\:border-b-0 {
    border-bottom-width: 0;
  }

  .md\:border-r {
    border-right-width: 1px;
  }

  .md\:px-3 {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .md\:pb-0 {
    padding-bottom: 0;
  }

  @media not all and (width >= 1024px) {
    .md\:max-lg\:inline {
      display: inline;
    }
  }
}

@media (width >= 1024px) {
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline {
    display: inline;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:w-2\/3 {
    width: 66.6667%;
  }

  .lg\:w-44 {
    width: 11rem;
  }

  .lg\:max-w-screen-md {
    max-width: 768px;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (width >= 1280px) {
  .xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:ml-auto {
    margin-left: auto;
  }

  .xl\:inline {
    display: inline;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-1\/4 {
    width: 25%;
  }

  .xl\:w-\[600px\] {
    width: 600px;
  }

  .xl\:w-auto {
    width: auto;
  }

  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:flex-row {
    flex-direction: row;
  }
}

@media (width >= 1536px) {
  .\32 xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }

  .\32 xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .\32 xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }

  .\32 xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .\32 xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .\32 xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.dark\:border-b-0:where(.dark, .dark *) {
  border-bottom-width: 0;
}

.dark\:border-blue-400:where(.dark, .dark *) {
  border-color: #57acd9;
}

.dark\:border-dark-100:where(.dark, .dark *) {
  border-color: #404040;
}

.dark\:border-dark-200:where(.dark, .dark *) {
  border-color: #303030;
}

.dark\:border-dark-300:where(.dark, .dark *) {
  border-color: #252525;
}

.dark\:border-dark-500:where(.dark, .dark *) {
  border-color: #121212;
}

.dark\:border-gray-100:where(.dark, .dark *) {
  border-color: #f8f8f8;
}

.dark\:border-gray-300:where(.dark, .dark *) {
  border-color: #cfcfcf;
}

.dark\:border-gray-500:where(.dark, .dark *) {
  border-color: #3c3c3c;
}

.dark\:border-green-400:where(.dark, .dark *) {
  border-color: #41a048;
}

.dark\:border-red-400:where(.dark, .dark *) {
  border-color: #f87171;
}

.dark\:border-transparent:where(.dark, .dark *) {
  border-color: #0000;
}

.dark\:border-white\/60:where(.dark, .dark *) {
  border-color: #fff9;
}

.dark\:border-yellow-400:where(.dark, .dark *) {
  border-color: #facc15;
}

.dark\:border-x-dark-200:where(.dark, .dark *) {
  border-left-color: #303030;
  border-right-color: #303030;
}

.dark\:border-y-dark-200:where(.dark, .dark *) {
  border-top-color: #303030;
  border-bottom-color: #303030;
}

.dark\:border-b-dark-100:where(.dark, .dark *) {
  border-bottom-color: #404040;
}

.dark\:border-b-dark-200:where(.dark, .dark *) {
  border-bottom-color: #303030;
}

.dark\:border-b-dark-400:where(.dark, .dark *) {
  border-bottom-color: #1a1a1a;
}

.dark\:border-b-gray-400:where(.dark, .dark *) {
  border-bottom-color: #737373;
}

.dark\:border-l-dark-200:where(.dark, .dark *) {
  border-left-color: #303030;
}

.dark\:border-r-dark-200:where(.dark, .dark *) {
  border-right-color: #303030;
}

.dark\:border-t-dark-200:where(.dark, .dark *) {
  border-top-color: #303030;
}

.dark\:border-t-dark-400:where(.dark, .dark *) {
  border-top-color: #1a1a1a;
}

.dark\:\!bg-dark-500:where(.dark, .dark *) {
  background-color: #121212 !important;
}

.dark\:\!bg-gray-300:where(.dark, .dark *) {
  background-color: #cfcfcf !important;
}

.dark\:\!bg-gray-400:where(.dark, .dark *) {
  background-color: #737373 !important;
}

.dark\:bg-black:where(.dark, .dark *) {
  background-color: #000;
}

.dark\:bg-blue-300:where(.dark, .dark *) {
  background-color: #84c2e5;
}

.dark\:bg-blue-500:where(.dark, .dark *) {
  background-color: #3192c6;
}

.dark\:bg-dark-100:where(.dark, .dark *) {
  background-color: #404040;
}

.dark\:bg-dark-200:where(.dark, .dark *) {
  background-color: #303030;
}

.dark\:bg-dark-300:where(.dark, .dark *) {
  background-color: #252525;
}

.dark\:bg-dark-400:where(.dark, .dark *) {
  background-color: #1a1a1a;
}

.dark\:bg-dark-500:where(.dark, .dark *) {
  background-color: #121212;
}

.dark\:bg-dark-500\/60:where(.dark, .dark *) {
  background-color: #12121299;
}

.dark\:bg-dark-500\/80:where(.dark, .dark *) {
  background-color: #121212cc;
}

.dark\:bg-gray-200:where(.dark, .dark *) {
  background-color: #e5e5e5;
}

.dark\:bg-gray-300:where(.dark, .dark *) {
  background-color: #cfcfcf;
}

.dark\:bg-gray-500:where(.dark, .dark *) {
  background-color: #3c3c3c;
}

.dark\:bg-green-300:where(.dark, .dark *) {
  background-color: #96cca3;
}

.dark\:bg-green-500:where(.dark, .dark *) {
  background-color: #338639;
}

.dark\:bg-orange-500:where(.dark, .dark *) {
  background-color: #d04b00;
}

.dark\:bg-peach-300:where(.dark, .dark *) {
  background-color: #ffd3ac;
}

.dark\:bg-peach-500:where(.dark, .dark *) {
  background-color: #fd9d65;
}

.dark\:bg-pink-300:where(.dark, .dark *) {
  background-color: #efb2bd;
}

.dark\:bg-purple-300:where(.dark, .dark *) {
  background-color: #cbbff3;
}

.dark\:bg-red-300:where(.dark, .dark *) {
  background-color: #fca5a5;
}

.dark\:bg-red-500:where(.dark, .dark *) {
  background-color: #cc3939;
}

.dark\:bg-transparent:where(.dark, .dark *) {
  background-color: #0000;
}

.dark\:bg-white:where(.dark, .dark *) {
  background-color: #fff;
}

.dark\:bg-white\/15:where(.dark, .dark *) {
  background-color: #ffffff26;
}

.dark\:bg-yellow-200:where(.dark, .dark *) {
  background-color: #fde68a;
}

.dark\:bg-yellow-300:where(.dark, .dark *) {
  background-color: #fcd34d;
}

.dark\:bg-yellow-500:where(.dark, .dark *) {
  background-color: #d97706;
}

.dark\:from-dark-500:where(.dark, .dark *) {
  --tw-gradient-from: #121212 var(--tw-gradient-from-position);
  --tw-gradient-to: #12121200 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.dark\:\!text-black:where(.dark, .dark *) {
  color: #000 !important;
}

.dark\:\!text-white:where(.dark, .dark *) {
  color: #fff !important;
}

.dark\:text-black:where(.dark, .dark *) {
  color: #000;
}

.dark\:text-gray-100:where(.dark, .dark *) {
  color: #f8f8f8;
}

.dark\:text-gray-200:where(.dark, .dark *) {
  color: #e5e5e5;
}

.dark\:text-gray-300:where(.dark, .dark *) {
  color: #cfcfcf;
}

.dark\:text-gray-500:where(.dark, .dark *) {
  color: #3c3c3c;
}

.dark\:text-green-100:where(.dark, .dark *) {
  color: #dcefde;
}

.dark\:text-red-100:where(.dark, .dark *) {
  color: #fee2e2;
}

.dark\:text-white:where(.dark, .dark *) {
  color: #fff;
}

.dark\:text-yellow-100:where(.dark, .dark *) {
  color: #fef9c3;
}

.dark\:text-yellow-400:where(.dark, .dark *) {
  color: #facc15;
}

.dark\:decoration-white:where(.dark, .dark *) {
  text-decoration-color: #fff;
}

.dark\:outline-dark-200:where(.dark, .dark *) {
  outline-color: #303030;
}

.dark\:ring-dark-500:where(.dark, .dark *) {
  --tw-ring-color: #121212;
}

.dark\:ring-offset-black:where(.dark, .dark *) {
  --tw-ring-offset-color: #000;
}

.dark\:after\:border-white\/40:where(.dark, .dark *):after {
  content: var(--tw-content);
  border-color: #fff6;
}

.after\:dark\:bg-dark-100:where(.dark, .dark *):after {
  content: var(--tw-content);
  background-color: #404040;
}

.group:hover .dark\:group-hover\:bg-dark-300:where(.dark, .dark *) {
  background-color: #252525;
}

.group:hover .dark\:group-hover\:text-white:where(.dark, .dark *) {
  color: #fff;
}

.peer:checked ~ .dark\:peer-checked\:after\:border-white\/40:where(.dark, .dark *):after {
  content: var(--tw-content);
  border-color: #fff6;
}

.dark\:data-\[checked\]\:border-white\/40[data-checked]:where(.dark, .dark *) {
  border-color: #fff6;
}

.dark\:data-\[state\=active\]\:border-gray-400[data-state="active"]:where(.dark, .dark *) {
  border-color: #737373;
}

.dark\:data-\[state\=active\]\:border-b-dark-500[data-state="active"]:where(.dark, .dark *) {
  border-bottom-color: #121212;
}

.dark\:data-\[highlighted\]\:bg-dark-200[data-highlighted]:where(.dark, .dark *) {
  background-color: #303030;
}

.dark\:data-\[state\=active\]\:bg-orange-500[data-state="active"]:where(.dark, .dark *) {
  background-color: #d04b00;
}

.dark\:data-\[placeholder\]\:text-gray-300[data-placeholder]:where(.dark, .dark *) {
  color: #cfcfcf;
}

.dark\:data-\[state\=active\]\:text-white[data-state="active"]:where(.dark, .dark *) {
  color: #fff;
}

.dark\:hover\:bg-black:hover:where(.dark, .dark *) {
  background-color: #000;
}

.dark\:hover\:bg-dark-300:hover:where(.dark, .dark *) {
  background-color: #252525;
}

.dark\:hover\:bg-dark-400:hover:where(.dark, .dark *) {
  background-color: #1a1a1a;
}

.dark\:hover\:bg-dark-500:hover:where(.dark, .dark *) {
  background-color: #121212;
}

.dark\:hover\:bg-gray-100:hover:where(.dark, .dark *) {
  background-color: #f8f8f8;
}

.dark\:hover\:bg-gray-400:hover:where(.dark, .dark *) {
  background-color: #737373;
}

.dark\:hover\:bg-gray-500:hover:where(.dark, .dark *) {
  background-color: #3c3c3c;
}

.dark\:hover\:bg-green-500:hover:where(.dark, .dark *) {
  background-color: #338639;
}

.dark\:hover\:bg-orange-500:hover:where(.dark, .dark *) {
  background-color: #d04b00;
}

.dark\:hover\:bg-red-500:hover:where(.dark, .dark *) {
  background-color: #cc3939;
}

.hover\:dark\:bg-gray-500:where(.dark, .dark *):hover {
  background-color: #3c3c3c;
}

.dark\:hover\:text-black:hover:where(.dark, .dark *) {
  color: #000;
}

.dark\:hover\:text-gray-100:hover:where(.dark, .dark *) {
  color: #f8f8f8;
}

.dark\:hover\:text-green-100:hover:where(.dark, .dark *) {
  color: #dcefde;
}

.dark\:hover\:text-red-100:hover:where(.dark, .dark *) {
  color: #fee2e2;
}

.dark\:hover\:text-white:hover:where(.dark, .dark *) {
  color: #fff;
}

.dark\:hover\:text-yellow-100:hover:where(.dark, .dark *) {
  color: #fef9c3;
}

.dark\:data-\[state\=active\]\:hover\:bg-orange-500:hover[data-state="active"]:where(.dark, .dark *) {
  background-color: #d04b00;
}

.focus\:dark\:bg-black:where(.dark, .dark *):focus {
  background-color: #000;
}

.dark\:disabled\:border-dark-100:disabled:where(.dark, .dark *) {
  border-color: #404040;
}

.dark\:disabled\:border-gray-500:disabled:where(.dark, .dark *) {
  border-color: #3c3c3c;
}

.dark\:disabled\:bg-dark-200:disabled:where(.dark, .dark *) {
  background-color: #303030;
}

.dark\:disabled\:bg-dark-300:disabled:where(.dark, .dark *) {
  background-color: #252525;
}

.dark\:disabled\:bg-dark-400:disabled:where(.dark, .dark *) {
  background-color: #1a1a1a;
}

.dark\:disabled\:text-gray-500:disabled:where(.dark, .dark *) {
  color: #3c3c3c;
}

.\[\&\:\:-webkit-color-swatch\]\:rounded::-webkit-color-swatch {
  border-radius: .25rem;
}

.\[\&\:\:-webkit-color-swatch\]\:border-none::-webkit-color-swatch {
  border-style: none;
}

.\[\&\>\.text-center\>a\]\:hidden > .text-center > a {
  display: none;
}

.\[\&\>span\]\:w-full > span {
  width: 100%;
}

.\[\&_\>_td\]\:\!bg-transparent > td {
  background-color: #0000 !important;
}

.\[\&_button\]\:hidden button {
  display: none;
}

.\[\&_code\]\:text-nowrap code {
  text-wrap: nowrap;
}

.\[\&_h2\]\:mt-12 h2 {
  margin-top: 3rem;
}

.\[\&_header\]\:text-right header {
  text-align: right;
}

.\[\&_header\]\:font-bold header {
  font-weight: 700;
}

.\[\&_header\]\:text-gray-500 header {
  color: #3c3c3c;
}

.\[\&_i\]\:text-green-500 i {
  color: #338639;
}

.\[\&_i\]\:text-red-500 i {
  color: #cc3939;
}

.\[\&_i\]\:hover\:text-white:hover i {
  color: #fff;
}

.\[\&_img\]\:w-full img {
  width: 100%;
}

.\[\&_input\]\:min-w-48 input {
  min-width: 12rem;
}
/*# sourceMappingURL=style.css.map */
